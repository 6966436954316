import { useEffect, useRef, useState } from 'react';

import { getRecord, updatePrototypeFoodComponent } from 'actions';
import { Disclaimer, ModalRef } from 'components';
import FoodComponentCalculatedMacrosModal from 'components/FoodComponentCalculatedMacrosModal/FoodComponentCalculatedMacrosModal';
import { FoodComponent, Ingredient, IngredientHeaderItem, PrototypeFoodComponent } from 'lib/interfaces';
import { isNil, omit, sortBy } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import { MeasurementUnit, Permission, ProcessingStage } from '@calo/dashboard-types';
import { Macros, Micronutrients } from '@calo/types';

import { Box } from '@mui/material';
import {
  ComponentHeaderCard,
  ComponentInformationCard,
  ComponentMethodCard,
  ComponentNameAndImageCard,
  CupsListCard,
  CustomSectionCard,
  IngredientListCard,
  MacrosMicrosInputCard
} from 'components/FoodComponentShared';
import { ChildComponentsCard } from 'components/FoodComponentShared/ExistingComponent';
import { FormOperation, InputValueRestrictionType } from 'lib/enums';
import { cleanComponentIngredients, isNumberInputValid } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import ActivityLogCard from 'views/ChefPlayground/Shared/ActivityLogCard';
import ComponentCommentsPopup from 'views/ChefPlayground/Shared/ComponentCommentsPopup';
import useFoodComponentForm from './useFoodComponentForm';

const ExactFoodComponent = () => {
  const roles = useUserRoles();
  const { id } = useParams<{ id: string }>();
  const calculatedMacrosRef = useRef<ModalRef>();
  const commentsPopupRef = useRef<ModalRef>();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [childComponents, setChildComponents] = useState<FoodComponent[]>([]);
  const [calculatedMacrosFromIngredients, setCalculatedMacrosFromIngredients] = useState<Macros | undefined>();
  const [calculatedMicronutrientsFromIngredients, setCalculatedMicronutrientsFromIngredients] = useState<
    Micronutrients | undefined
  >();
  const [isCalculatedMacrosDifferent, setIsCalculatedMacrosDifferent] = useState(false);
  const [structuredIngredients, setStructuredIngredients] = useState<IngredientHeaderItem[]>([]);

  const {
    data: foodComponent,
    isLoading,
    refetch
  } = useQuery<any, Error, FoodComponent>([`food-components/prototype/${id}`], getRecord, {
    suspense: true,
    keepPreviousData: false
  });

  const isUpdateDisabled = (foodComponent?.prototype && foodComponent?.stage !== ProcessingStage.draft) || false;

  const isDisabled = !roles.includes(Permission.UPDATE_PROTOTYPE_COMPONENT) || isUpdateDisabled;

  const { mutateAsync: updateMutation } = useMutation(updatePrototypeFoodComponent);

  const handleUpdateFood = (values: Partial<FoodComponent>, structuredIngredients: IngredientHeaderItem[]) => {
    const updatedIngredients: Ingredient[] = [];
    let tempHeaderName: string | null | undefined = null;
    for (const structuredIngredient of structuredIngredients) {
      if (structuredIngredient.type === 'header') {
        tempHeaderName = structuredIngredient.header;
      } else {
        updatedIngredients.push({ ...structuredIngredient.ingredient, header: tempHeaderName ?? undefined });
      }
    }

    const cleanedIngredients = cleanComponentIngredients(updatedIngredients);

    const { purchasingCost: _purchasingCost, prototype: _prototype, parentComponentIds: _, ...rest } = values;
    return updateMutation({ id, ...rest, ingredients: cleanedIngredients as any });
  };

  const submitValues = async (values: Partial<FoodComponent>) => {
    await handleUpdateFood(values, structuredIngredients);
  };

  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, setFieldValue, setValues, errors } =
    useFoodComponentForm(foodComponent, submitValues);

  let currentHeader: string | null | undefined;
  useEffect(() => {
    const sortedIngredients = sortBy(values.ingredients, [(ingredient) => (isNil(ingredient.header) ? -1 : 0), 'header']);

    const updatedStructuredIngredients: IngredientHeaderItem[] = [];
    for (const ingredient of sortedIngredients) {
      if (ingredient.header !== currentHeader && ingredient.header !== null && ingredient.header !== undefined) {
        currentHeader = ingredient.header;
        updatedStructuredIngredients.push({ type: 'header', header: currentHeader });
      }
      updatedStructuredIngredients.push({ type: 'ingredient', ingredient });
    }

    setStructuredIngredients(updatedStructuredIngredients);
  }, [values.ingredients]);

  useEffect(() => {
    if (!foodComponent) {
      return;
    }
    setChildComponents([...(foodComponent.childComponents as unknown as FoodComponent[])]);
    setValues({
      ...(omit(foodComponent, ['id', 'createdAt', 'updatedAt', 'usedOnMenu', 'lastUsedOnMenu', 'imageUrl']) as FoodComponent),
      micronutrients: {
        addedSugar: foodComponent?.micronutrients?.addedSugar || 0,
        cholesterol: foodComponent?.micronutrients?.cholesterol || 0,
        saturatedFats: foodComponent?.micronutrients?.saturatedFats || 0,
        sodium: foodComponent?.micronutrients?.sodium || 0,
        totalSugar: foodComponent?.micronutrients?.totalSugar || 0,
        transFats: foodComponent?.micronutrients?.transFats || 0
      },
      childComponents: foodComponent.childComponents?.map((child) => ({ id: child.id, quantity: child.quantity }))
    });
  }, [foodComponent]);

  const handleChangeForNumRestrictions = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (isNumberInputValid(value, InputValueRestrictionType.macros)) {
      setFieldValue(`${name}`, Number(value));
    }
  };

  const addCommentSuccessHandler = (data: FoodComponent) => {
    setFieldValue('prototypeActions', data.prototypeActions);
    refetch();
  };

  return (
    <>
      {foodComponent && (
        <>
          <ComponentHeaderCard
            operation={FormOperation.update}
            foodComponent={foodComponent as PrototypeFoodComponent}
            isValid={isValid}
            isSubmitting={isSubmitting}
            isEdit={isEdit}
            isDisabled={isDisabled}
            handleSubmit={handleSubmit}
            refetch={refetch}
          />
          <Box mt={'16px'} mb={'16px'}>
            <Disclaimer type="component" />
          </Box>
          <ComponentNameAndImageCard
            foodComponent={foodComponent}
            values={values}
            errors={errors}
            isLoading={isLoading}
            handleChange={handleChange}
            isDisabled={isDisabled}
          />
          <IngredientListCard
            values={values}
            prototype={roles.includes(Permission.VIEW_PROTOTYPE_INGREDIENT_LIST)}
            childComponents={childComponents}
            structuredIngredients={structuredIngredients}
            setValues={setValues}
            setFieldValue={setFieldValue}
            setStructuredIngredients={setStructuredIngredients}
          />
          <ChildComponentsCard
            prototype={true}
            values={values}
            foodComponent={foodComponent}
            childComponents={childComponents}
            setChildComponents={setChildComponents}
            setValues={setValues}
            setFieldValue={setFieldValue}
            setIsCalculatedMacrosDifferent={setIsCalculatedMacrosDifferent}
            setCalculatedMicronutrientsFromIngredients={setCalculatedMicronutrientsFromIngredients}
            setCalculatedMacrosFromIngredients={setCalculatedMacrosFromIngredients}
            isDisabled={isDisabled}
          />
          <MacrosMicrosInputCard
            operation={FormOperation.update}
            prototype={true}
            values={values}
            errors={errors}
            setValues={setValues}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleChangeForNumRestrictions={handleChangeForNumRestrictions}
            calculatedMacrosRef={calculatedMacrosRef}
            isCalculatedMacrosDifferent={isCalculatedMacrosDifferent}
            calculatedMacrosFromIngredients={calculatedMacrosFromIngredients}
            calculatedMicronutrientsFromIngredients={calculatedMicronutrientsFromIngredients}
            isDisabled={isDisabled}
          />
          <ComponentInformationCard
            values={values}
            errors={errors}
            childComponents={childComponents}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleChangeForNumRestrictions={handleChangeForNumRestrictions}
            isDisabled={isDisabled}
          />
          <CustomSectionCard values={values} setFieldValue={setFieldValue} errors={errors} isDisabled={isDisabled} />
          {values.measurementUnit === MeasurementUnit.cup && (
            <CupsListCard
              values={values}
              setValues={setValues}
              setFieldValue={setFieldValue}
              isDisabled={isDisabled}
              operation={FormOperation.update}
            />
          )}
          <ComponentMethodCard
            operation={FormOperation.update}
            prototype={true}
            values={values}
            errors={errors}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setValues={setValues}
            setFieldValue={setFieldValue}
            isDisabled={isDisabled}
          />
          <ActivityLogCard
            actionLogs={foodComponent.prototypeActions ?? []}
            type="component"
            addCommentClickHandler={() => commentsPopupRef.current?.open()}
          />
        </>
      )}
      <FoodComponentCalculatedMacrosModal
        calculatedMacrosFromIngredients={calculatedMacrosFromIngredients}
        calculatedMacrosRef={calculatedMacrosRef}
        calculatedMicronutrientsFromIngredients={calculatedMicronutrientsFromIngredients}
      />
      <ComponentCommentsPopup
        selectedComponent={foodComponent}
        ref={commentsPopupRef}
        closeCommentsPopup={() => commentsPopupRef.current?.close()}
        successHandler={addCommentSuccessHandler}
      />
    </>
  );
};

export default ExactFoodComponent;
