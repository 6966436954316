import { Brand, RetailBranch } from '@calo/types';
import { updateRetailMenu } from 'actions/index';
import { Routes } from 'lib/enums';
import { baristaEmailMapping } from '../../constant';
import { getCountryFromBranch } from '../../retailHelpers';
import { RetailFilter } from '../../types';
import { RetailMenuData } from './types';

export const initializeFilters = (branchFromPath: string, userEmail: string, searchParams: URLSearchParams): RetailFilter => ({
  brand: Brand.CALO,
  branch: RetailBranch[branchFromPath] ? branchFromPath : (baristaEmailMapping[userEmail]?.[0] ?? RetailBranch.SEEF),
  ...JSON.parse(searchParams.get('filters') || `{}`)
});

export const updateSearchParams = (filters: RetailFilter, searchParams: URLSearchParams, history: any, location: any) => {
  searchParams.set('filters', JSON.stringify(filters));
  history.push({ pathname: location.pathname, search: searchParams.toString() });
};

export const updateRetailMenuState = (
  filters: RetailFilter,
  data: RetailMenuData,
  previousBranch: React.MutableRefObject<string>,
  setRetailMenu: React.Dispatch<React.SetStateAction<RetailMenuData>>
) => {
  if (previousBranch.current === filters.branch) {
    setRetailMenu({
      food: data?.food?.filter((item) => filterFoodItem(item, filters))
    });
  } else {
    setRetailMenu(data || {});
    previousBranch.current = filters.branch;
  }
};

const filterFoodItem = (item: any, filters: RetailFilter) => {
  const matchesFoodCategory = filters.foodCategory?.length
    ? filters.foodCategory?.some((category) => item.retail?.retailTagList.includes(category))
    : true;
  const matchesStatus = filters.status ? (filters.status === 'Active' ? !item.retail?.disabled : item.retail?.disabled) : true;
  const matchesSize = filters.size ? item.size?.includes(filters.size) : true;
  const matchesStartDate = filters.startDate ? new Date(item.date) >= filters.startDate : true;
  const matchesEndDate = filters.endDate ? new Date(item.date) <= filters.endDate : true;

  return matchesFoodCategory && matchesStatus && matchesSize && matchesStartDate && matchesEndDate;
};

export const navigateToEditPage = (filters: RetailFilter, itemId: string | undefined, history: any) => {
  history.push(
    Routes.retailNewMenu
      .replace(':brand', filters.brand)
      .replace(':branch', filters.branch)
      .replace(':id', itemId || ':id')
  );
};

export const filterMenuBySearchTerm = (food: any[] | undefined, searchTerm: string) => {
  return food?.filter((item) => {
    const name = item.name?.en?.toLowerCase() || '';
    // @ts-ignore
    const tags = item.retail?.retailTagList?.map((tag) => tag.toLowerCase()) || [];
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return name.includes(lowerCaseSearchTerm) || tags.some((tag) => tag.includes(lowerCaseSearchTerm));
  });
};

export const mapRetailFoodList = (data?: RetailMenuData) => {
  return (
    data?.food.map((f) => ({
      id: f.id,
      priority: 1,
      retailPrice: f.retail.retailPrice,
      retailTagList: f.retail.retailTagList,
      foodModifiers: f.retail.foodModifiers.map((item) => ({
        ...item,
        componentOptions: item.componentOptions.map((option) => ({
          id: option.id,
          quantity: option.quantity
        }))
      })),
      disabled: !!f.retail.disabled,
      swapAvailable: !!f.retail.swapAvailable,
      startDate: f.retail.startDate,
      endDate: f.retail.endDate
    })) || []
  );
};

export const updateMenuWithDeletedItem = async (
  id: string,
  retailFoodList: any[],
  filters: RetailFilter,
  data: RetailMenuData | undefined,
  refetch: any,
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const filteredMenu = retailFoodList?.filter((item) => item.id !== id) || [];
  try {
    setIsSaving(true);
    await updateRetailMenu({
      brand: filters.brand,
      branch: filters.branch,
      // @ts-ignore
      menuId: data?.id || '',
      country: getCountryFromBranch(filters.branch),
      // @ts-ignore
      food: filteredMenu
    });
    refetch();
  } catch (error) {
    console.log(error);
  } finally {
    setIsSaving(false);
  }
};

export const updateMenuItemVisibility = async (
  id: string,
  retailFoodList: any[],
  filters: RetailFilter,
  data: RetailMenuData,
  refetch: any,
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const updatedMenu = retailFoodList.map((item) => (item.id === id ? { ...item, disabled: !item.disabled } : item));
  try {
    setIsSaving(true);
    await updateRetailMenu({
      brand: filters.brand,
      branch: filters.branch,
      // @ts-ignore
      menuId: data?.id || '',
      country: getCountryFromBranch(filters.branch),
      food: updatedMenu
    });
    await refetch();
  } catch (error) {
    console.log(error);
  } finally {
    setIsSaving(false);
  }
};

export const paginateMenu = (filteredMenu: any[] | undefined, currentPage: number, itemsPerPage: number) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return filteredMenu?.slice(startIndex, endIndex);
};
