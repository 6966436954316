/* eslint-disable unicorn/no-thenable */
import { CodeChannel, CouponCategory, CouponMode, CreateBulkCouponsClientReq } from '@calo/dashboard-types';
import { Brand, CouponType, Currency } from '@calo/types';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  numberOfCoupons: yup
    .number()
    .required('Number of coupons is required')
    .min(2, 'Number of coupons must be at least 2')
    .max(1000, 'Number of coupons must be at most 1000')
    .integer('Number of coupons must be an Integer'),
  prefix: yup.string().required('Prefix is required').min(3, 'Prefix must be at least 3 characters'),
  amount: yup
    .number()
    .required('Amount is required')
    .moreThan(0, 'Amount must be more than 0')
    .max(100, 'Amount must be at most 100'),
  expiresAt: yup.string().optional()
});

export default (onSubmit: (values: Omit<CreateBulkCouponsClientReq, 'id'>) => Promise<void>) =>
  useFormik<Omit<CreateBulkCouponsClientReq, 'id'>>({
    initialValues: {
      numberOfCoupons: 0,
      prefix: '',
      amount: 0,
      expiresAt: '',
      rules: [],
      currency: Currency.BHD,
      category: CouponCategory.promo,
      type: CouponType.PERCENTAGE,
      channel: CodeChannel.CALO_PROMOS,
      channelCategory: undefined,
      assignee: '',
      brand: Brand.CALO,
      mode: CouponMode.DISCOUNT
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        values.prefix = values.prefix + '-';
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
