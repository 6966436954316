import { Cancel, Edit, LogoutOutlined, Sort } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material';
import { ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import Modal, { ModalRef } from 'components/Modal';
import { useMemo, useRef, useState } from 'react';
import { PickUpStatus, PickUpType } from '../../types';
import { CancelOrderDialog } from './CancelOrderDialog';
import { EditOrderDialog } from './EditOrderDialog';
import OrderTableRow from './OrderTableRow';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    justifyContent: 'space-between'
  }
}));

interface OrderTableProps {
  orders: any[];
  loadingItemIds: string[];
  isFetching: boolean;
  isLoading: boolean;
  selectedTabStatus: PickUpStatus | undefined | '';
  onChangeStatus: (pickUpId: string, status: PickUpStatus, reason?: string) => void;
  handleItemUpdate: ({ items, orderId, orderNo }: { items: any[]; orderId: string; orderNo: string }) => void;
  onRequestSort: (property: 'createdAt' | 'pickUpAt') => void;
  order: 'asc' | 'desc';
  orderBy: 'createdAt' | 'pickUpAt';
}

export enum DialogAction {
  CANCEL = 'cancel',
  EDIT = 'edit'
}

interface DialogDetails {
  anchorEl: HTMLElement | null;
  order: any;
  action: DialogAction | null;
}

export const OrderTable = ({
  orders,
  loadingItemIds,
  isFetching,
  isLoading,
  selectedTabStatus = '',
  onChangeStatus,
  handleItemUpdate,
  onRequestSort,
  order,
  orderBy
}: OrderTableProps) => {
  const [dialogDetails, setDialogDetails] = useState<DialogDetails | null>(null);
  const modalRef = useRef<ModalRef>(null);
  const handleMenuAction = (value: DialogAction) => {
    setDialogDetails((prev) => (prev ? { ...prev, anchorEl: null, action: value } : null));
    modalRef.current?.open();
  };

  const isCancellationAllowed = useMemo(
    () => selectedTabStatus === PickUpStatus.CREATED || selectedTabStatus === PickUpStatus.READY_FOR_PICK_UP,
    [selectedTabStatus]
  );

  const commonHeaders = useMemo(
    () => [
      { id: 'orderId', label: 'Order', sortable: false, show: true },
      { id: 'customer', label: 'Customer', sortable: false, show: true },
      { id: 'createdAt', label: 'Created On', sortable: true, show: true },
      { id: 'pickUpAt', label: 'Pickup Time', sortable: true, show: true },
      { id: 'status', label: 'Status', sortable: false, show: true },
      { id: 'items', label: 'Items', sortable: false, show: true },
      { id: 'comments', label: 'Comments', sortable: false, show: true },
      { id: 'reasons', label: 'Reasons', sortable: false, show: selectedTabStatus === PickUpStatus.CANCELLED },
      { id: 'actions', label: 'Actions', sortable: false, show: isCancellationAllowed },
      { id: 'totalItems', label: 'Total Items', sortable: false, show: true }
    ],
    [selectedTabStatus]
  );

  const handleOnMyWay = (id: string) => {
    onChangeStatus(id, PickUpStatus.BARISTA_EN_ROUTE);
    modalRef.current?.close();
  };

  return (
    <Table
      sx={{
        marginY: '4px',
        minHeight: '120px',
        overflow: 'hidden',
        width: '95%',
        mx: 'auto',
        boxShadow: '2',
        borderRadius: '15px',
        mt: '32px',
        backgroundColor: 'white',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <TableHead
        style={{ borderRadius: '8px' }}
        sx={{
          backgroundColor: 'rgba(238, 238, 238, 1)',
          color: 'black',
          flexWrap: 0,
          justifyContent: 'space-between',
          width: '100%',
          borderRadius: '8px'
        }}
      >
        <TableRow>
          {commonHeaders.map(
            (header) =>
              header.show && (
                <StyledTableCell key={header.id}>
                  {header.sortable ? (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={() => onRequestSort(header.id as 'createdAt' | 'pickUpAt')}
                      IconComponent={Sort}
                      sx={{
                        '& .MuiTableSortLabel-icon': {
                          opacity: 0.5
                        },
                        '&.Mui-active .MuiTableSortLabel-icon': {
                          opacity: 1
                        }
                      }}
                    >
                      <strong>{header.label}</strong>
                    </TableSortLabel>
                  ) : (
                    <strong>{header.label}</strong>
                  )}
                </StyledTableCell>
              )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((order, index) => (
          <OrderTableRow
            key={index}
            order={order}
            loadingItemIds={loadingItemIds}
            isFetching={isFetching}
            isLoading={isLoading}
            onChangeStatus={onChangeStatus}
            selectedTabStatus={selectedTabStatus}
            isCancellationAllowed={isCancellationAllowed}
            handleActions={({ anchorEl, order }) => {
              setDialogDetails({ anchorEl, order, action: null });
            }}
          />
        ))}
      </TableBody>
      <Menu
        anchorEl={dialogDetails?.anchorEl}
        open={Boolean(dialogDetails?.anchorEl)}
        onClose={() => setDialogDetails(null)}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {dialogDetails?.order.pickUp.pickUpType === PickUpType.CAR_HOP && (
          <MenuItem
            onClick={() => {
              handleOnMyWay(dialogDetails.order.pickUp.id);
              setDialogDetails(null);
            }}
            disabled={!dialogDetails.order.pickUp.customerArrivedAt}
          >
            <ListItemAvatar sx={{ minWidth: '40px' }}>
              <LogoutOutlined color="action" />
            </ListItemAvatar>
            <ListItemText primary="I'm on my way" secondary="Let the user know you're bringing their order" />
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuAction(DialogAction.CANCEL)}>
          <ListItemAvatar sx={{ minWidth: '40px' }}>
            <Cancel color="action" />
          </ListItemAvatar>
          <ListItemText primary="Cancel Order" secondary="Cancel the entire order" />
        </MenuItem>
        <MenuItem onClick={() => handleMenuAction(DialogAction.EDIT)}>
          <ListItemAvatar sx={{ minWidth: '40px' }}>
            <Edit color="action" />
          </ListItemAvatar>
          <ListItemText primary="Edit Order" secondary="Edit the order" />
        </MenuItem>
      </Menu>

      <Modal
        isNarrow
        ref={modalRef}
        onClose={() => {
          modalRef.current?.close();
          setDialogDetails(null);
        }}
      >
        {dialogDetails && dialogDetails.action === DialogAction.CANCEL && (
          <CancelOrderDialog
            order={dialogDetails.order}
            onClose={() => modalRef.current?.close()}
            handleCancelOrder={({ reason }) => {
              onChangeStatus(dialogDetails.order.pickUp.id, PickUpStatus.CANCELLED, reason);
              modalRef.current?.close();
            }}
          />
        )}
        {dialogDetails && dialogDetails.action === DialogAction.EDIT && (
          <EditOrderDialog
            order={dialogDetails.order}
            onClose={() => modalRef.current?.close()}
            handleEditOrder={({ items, orderId, orderNo }) => {
              handleItemUpdate({ items, orderId, orderNo });
              modalRef.current?.close();
            }}
          />
        )}
      </Modal>
    </Table>
  );
};
