import { Typography } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { CaloLoader, ModalRef } from 'components';
import AddMealsByFilterPopup from 'components/AddMealsByFilterPopup';
import Popup from 'components/Popup';
import { format } from 'date-fns/fp';
import { useFoodListByFilters } from 'hooks';
import { getMenuTagsFromFoodList } from 'lib/helpers';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import { every, isEmpty } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { filterMenus, findMenusWithFoodCost } from '../../helpers';
import { WeekMenuListProps } from '../../types';
import MenuListCard from './MenuListCard';

const isFoodEmptyInMenus = (menus: Menu[]) => {
  return every(menus, (menu) => isEmpty(menu.food));
};

const WeeklyMenuListCards = ({
  filters,
  foodIds,
  isLoading,
  isEditable,
  selectedYear,
  selectedWeek,
  selectedMenu,
  weeklyMenuList,
  isAddMealsPopupOpen,
  setSelectedMenu,
  setWeeklyAverageCost,
  setIsAddMealsPopupOpen,
  setWeeklyMenuList
}: WeekMenuListProps) => {
  const addMealsByFiltersRef = useRef<ModalRef>();

  const [filtersOn, setFiltersOn] = useState<boolean>(false);
  const [foodToReplace, setFoodToReplace] = useState<{ food: MenuFood }>();
  const [menuFoodIds, setMenuFoodIds] = useState<string[]>([]);
  const [selectedMenuFilters, setSelectedMenuFilters] = useState({
    type: '',
    plan: '',
    protein: '',
    taste: '',
    sandwich: undefined,
    lastUsed: '',
    base: '',
    category: '',
    mealName: '',
    menuLabel: ''
  });

  useEffect(() => {
    if (foodToReplace && selectedMenu) {
      const menuDate = new Date(selectedMenu.id);
      handleDateSelected(menuDate);
    }
  }, [selectedMenu, foodToReplace]);

  const handleDateSelected = (selectedDate: Date) => {
    const formattedDate = format('yyyy-MM-dd')(selectedDate);
    const menuData = weeklyMenuList?.find((menu) => menu.id === formattedDate);
    if (menuData && menuData.food) {
      const newMenuFoodIds = menuData.food.map((f) => f.id);
      setMenuFoodIds(newMenuFoodIds);
    }
  };

  const filteredMenuLists = filterMenus(weeklyMenuList);
  const { foodListFiltersData, foodFiltersLoading, hasNextPage, fetchNextPage, isLoadingFetchNextPage } = useFoodListByFilters({
    selectedMenuFilters,
    country: filters.country,
    kitchen: filters.kitchen,
    brand: filters.brand,
    filtersOn: true
  });

  const menusWithFoodCostObject = useMemo(() => {
    return findMenusWithFoodCost(weeklyMenuList, filters.kitchen);
  }, [weeklyMenuList, foodIds]);

  useEffect(() => {
    if (Object.values(menusWithFoodCostObject).length > 0) {
      const allValues = Object.values(menusWithFoodCostObject).flatMap((menu) => Object.values(menu));
      const sum = allValues.reduce((acc, value) => acc + value, 0);
      const average = sum / allValues.length;
      setWeeklyAverageCost(average);
    }
  }, [Object.keys(menusWithFoodCostObject)]);

  const handleClosePopup = () => {
    setSelectedMenuFilters({
      type: '',
      plan: '',
      protein: '',
      taste: '',
      sandwich: undefined,
      lastUsed: '',
      base: '',
      category: '',
      mealName: '',
      menuLabel: ''
    });
    setFiltersOn(false);
    setSelectedMenu(undefined);
    setFoodToReplace(undefined);
    setIsAddMealsPopupOpen(false);
  };

  useEffect(() => {
    if (isAddMealsPopupOpen) {
      addMealsByFiltersRef.current?.open();
    } else {
      setIsAddMealsPopupOpen(false);
      addMealsByFiltersRef.current?.close();
    }
  }, [isAddMealsPopupOpen]);

  const handleRemoveFood = (food: MenuFood, menuId: string) => {
    const menuIndex = weeklyMenuList.findIndex((menu) => menu.id === menuId);
    if (menuIndex < 0) {
      toast('Menu to update not found', { type: 'error' });
      return;
    }

    const updatedMenuList = [...weeklyMenuList];
    const menuTime = new Date(menuId).getTime();
    const updatedFood = updatedMenuList[menuIndex].food.filter((f) => f.name.en !== food.name.en);
    const updatedTags = getMenuTagsFromFoodList(updatedFood as Food[], menuTime);

    updatedMenuList[menuIndex] = {
      ...updatedMenuList[menuIndex],
      food: updatedFood,
      tags: updatedTags
    };
    setWeeklyMenuList(updatedMenuList);
  };

  const handleReplaceFood = (selectedFood: MenuFood[]) => {
    if (!selectedMenu || !foodToReplace) {
      toast('No selected Menu to replace from', { type: 'error' });
      return;
    }
    const menuIndex = weeklyMenuList.findIndex((menu) => menu.id === selectedMenu.id);

    if (menuIndex < 0) {
      toast('Menu not found and cannot be updated', { type: 'error' });
      return;
    }

    const updatedMenuList = [...weeklyMenuList];
    const menuDay = new Date(selectedMenu.id).getTime();

    const updatedFood = updatedMenuList[menuIndex].food.filter((f) => f.name.en !== foodToReplace.food.name.en);
    const updatedTags = getMenuTagsFromFoodList((updatedFood as Food[]) ?? [], menuDay);

    updatedMenuList[menuIndex] = {
      ...updatedMenuList[menuIndex],
      food: [...updatedFood, ...selectedFood.map((f) => ({ ...f, new: true }))],
      tags: updatedTags
    };

    setWeeklyMenuList(updatedMenuList);
  };

  useEffect(() => {
    if (!weeklyMenuList) {
      return;
    }

    if (foodToReplace) {
      const updatedFilters = {
        ...selectedMenuFilters
        // type: foodToReplace.food.type[0] ?? '',
      };
      setSelectedMenuFilters(updatedFilters);
      setIsAddMealsPopupOpen(true);
    }
  }, [foodToReplace]);

  if (isLoading) {
    return <CaloLoader />;
  }

  if (weeklyMenuList.length === 0) {
    return (
      <Typography sx={{ textAlign: 'center', fontSize: '32px', marginTop: 2, color: caloThemeV2.palette.text.disabled }}>
        No menu for this week
      </Typography>
    );
  }

  return (
    <>
      {filteredMenuLists.map(
        ({ category, filteredMenuList }) =>
          !isFoodEmptyInMenus(filteredMenuList) && (
            <MenuListCard
              key={category}
              isEditable={isEditable}
              categoryName={category}
              setFoodToReplace={setFoodToReplace}
              setSelectedMenu={setSelectedMenu}
              handleRemoveFood={handleRemoveFood}
              filteredMenuList={filteredMenuList}
              menusWithFoodCostObject={menusWithFoodCostObject}
            />
          )
      )}

      <Popup maxWidth="lg" ref={addMealsByFiltersRef} onClose={handleClosePopup}>
        <AddMealsByFilterPopup
          filtersOn={filtersOn}
          foodIds={menuFoodIds ?? foodIds}
          foodToReplace={foodToReplace}
          selectedWeek={selectedWeek}
          selectedYear={selectedYear}
          fetchNextPage={fetchNextPage}
          isLoading={foodFiltersLoading}
          hasNextPage={hasNextPage || false}
          handleClosePopup={handleClosePopup}
          values={(selectedMenu as any) || []}
          handleReplaceFood={(food: Food[]) => {
            handleReplaceFood(food);
            handleClosePopup();
          }}
          selectedMenuFilters={selectedMenuFilters}
          addMealsByFiltersRef={addMealsByFiltersRef}
          isFetchingNextPage={isLoadingFetchNextPage}
          setSelectedMenuFilters={setSelectedMenuFilters}
          onDateSelected={handleDateSelected}
          foodListFiltersData={(foodListFiltersData || []).filter((f) => !f.deletedAt)}
          handleFoodChanges={() => {}}
        />
      </Popup>
    </>
  );
};

export default WeeklyMenuListCards;
