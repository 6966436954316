import { Permission } from '@calo/dashboard-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, Pagination, Stack, TextField, Typography } from '@mui/material';
import { toggleUISettings } from 'actions/ui';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { useUserRoles } from 'lib/hooks';
import { useDispatch } from 'react-redux';
import { getKitchenFromBranch } from '../retailHelpers';
import RetailMenuRow from './RetailMenuRow';
import Settings from './RetailMenuSettings';
import { useRetailMenu } from './useRetailMenu/useRetailMenu';

const RetailMenu = () => {
  const {
    filters,
    setFilters,
    isLoading,
    isUnauthorized,
    handleEditClick,
    totalItems,
    activeItems,
    handleSearchChange,
    filteredMenu,
    handleDeleteItem,
    isSaving,
    paginatedMenu,
    currentPage,
    handlePageChange,
    itemsPerPage,
    toggleMenuItemVisibility
  } = useRetailMenu();

  const roles = useUserRoles();
  const dispatch = useDispatch();

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
        width="100%"
      >
        <Stack width={'50%'} display={'flex'} flexDirection={'column'} alignItems="start" justifyContent={'space-between'}>
          <Typography
            sx={{
              mt: 'auto',
              fontWeight: 500,
              fontSize: '16px',
              mb: '8px',
              color: caloTheme.palette.neutral700,
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            Retail <ChevronRightIcon sx={{ fontSize: '16px', mx: '8px' }} />
            <span style={{ color: caloTheme.palette.neutral900 }}>Retail Menu</span>
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mt: 'auto',
              fontWeight: 600,
              fontSize: '33px',
              color: caloTheme.palette.neutral900,
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            Retail Menu {filters.branch}
          </Typography>
        </Stack>
      </Box>

      <Box display="flex" justifyContent="flex-start" sx={{ padding: 2 }}>
        <Box
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '16px',
            width: '270px',
            textAlign: 'left',
            mr: '24px',
            backgroundColor: '#fff'
          }}
        >
          <Typography variant="body2" sx={{ color: '#757575', mb: '16px', fontWeight: 'bold' }}>
            TOTAL ITEMS
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {totalItems.toLocaleString()}
          </Typography>
        </Box>
        <Box
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '16px',
            width: '270px',
            textAlign: 'left',
            backgroundColor: '#fff'
          }}
        >
          <Typography variant="body2" sx={{ color: '#757575', mb: '16px', fontWeight: 'bold' }}>
            ACTIVE ITEMS
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {activeItems.toLocaleString()}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: '12px', ml: '16px', display: 'flex', gap: 2, alignItems: 'center' }}>
        <Button
          variant="outlined"
          onClick={() => dispatch(toggleUISettings())}
          startIcon={<i className="fas fa-filter" id="filter" style={{ fontSize: '16px' }}></i>}
          sx={{
            color: 'text.primary',
            borderColor: 'grey.900',
            fontWeight: 'bold',
            textTransform: 'none',
            '&:hover': {
              borderColor: 'grey.600',
              backgroundColor: 'grey.50'
            },
            py: '6.5px',
            px: '24px'
          }}
        >
          FILTER
        </Button>

        <TextField
          placeholder="Search"
          size="small"
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={{ backgroundColor: '#fff' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
        />
        {!isUnauthorized && roles.includes(Permission.UPDATE_RETAIL_MENU_ITEMS) && (
          <Stack direction="row" spacing={2} sx={{ ml: 'auto', mr: 2 }}>
            <Button
              variant="contained"
              startIcon={<i className="fas fa-plus" style={{ color: '#fff' }} />}
              onClick={() => handleEditClick()}
              sx={{ color: '#fff' }}
            >
              Add New sku
            </Button>
          </Stack>
        )}
      </Box>
      <Settings onFilter={setFilters} filters={filters} />
      <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
        {isUnauthorized ? (
          <Typography variant="h6" sx={{ textAlign: 'center', marginY: 2 }}>
            You are not authorized to view this Branch
          </Typography>
        ) : isLoading || isSaving ? (
          <Stack sx={{ width: '100%', justifyContent: 'center' }}>
            <CaloLoader />
          </Stack>
        ) : paginatedMenu && paginatedMenu.length > 0 ? (
          <RetailMenuRow
            retailMenu={paginatedMenu}
            toggleMenuItemVisibility={toggleMenuItemVisibility}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteItem}
            kitchen={getKitchenFromBranch(filters.branch)}
          />
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center', marginY: 2 }}>
            No Retail Menu added
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Pagination
          count={Math.ceil((filteredMenu?.length || 0) / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          // color="primary"
          showFirstButton
          showLastButton
          variant="text"
          size="large"
          shape="circular"
        />
      </Box>
    </Box>
  );
};

export default RetailMenu;
