import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';

export const enum ImageVariant {
  square1x = 'square@1x.jpg',
  square2x = 'square@2x.jpg',
  square3x = 'square@3x.jpg'
}

export const getImageUrl = (imageUrl: string | undefined, variant: ImageVariant) => {
  if (imageUrl) {
    return `${imageUrl}/${variant}`;
  }
  return noImagePlaceholder;
};
