import { Permission } from '@calo/dashboard-types';
import { Box, Stack, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { format, parseISO } from 'date-fns/fp';
import { getImageUrl, ImageVariant } from 'lib/helpers/getImageUrl';
import { useUserRoles } from 'lib/hooks';
import { FeedBackData } from 'lib/interfaces';
import { capitalize, startCase } from 'lodash-es';
import { Icon } from '../..';
import { handleChangeURL } from './helpers';
import styles from './styles';
interface FeedbackRowProps {
  user: FeedBackData;
}

const FeedbackRow = ({ user }: FeedbackRowProps) => {
  const roles = useUserRoles();

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const stars = (rate: number) => {
    const allStars: JSX.Element[] = [];
    for (let i = 1; i <= rate; i++) {
      allStars.push(<Icon name="star" size={4} />);
    }
    for (let i = 5; i > rate; i--) {
      allStars.push(<Icon name="grayStar" size={4} />);
    }
    return allStars;
  };

  return (
    <TableRow hover>
      <StyledTableCell component="th" scope="row" sx={{ textOverflow: 'ellipsis' }}>
        <Box sx={styles.rowStack}>
          <Stack sx={styles.rowStack}>
            <img
              width={80}
              alt={user.food.name.en}
              className="object-cover h-140 rounded"
              onError={(e: any) => (e.target.src = noImagePlaceholder)}
              src={getImageUrl(user.food.imageUrl, ImageVariant.square2x)}
            />
            <Stack sx={styles.infoStack}>
              <Typography sx={styles.foodName}>
                {roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) ? (
                  <a onClick={() => handleChangeURL(user.food.id)}>{user.food.name.en}</a>
                ) : (
                  `${user.food.name.en}`
                )}
              </Typography>
              <Typography>{stars(user.rating)}</Typography>
            </Stack>
          </Stack>
        </Box>
      </StyledTableCell>

      <StyledTableCell>
        <Typography sx={styles.reviewTypography}>{user.review || '---'}</Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography sx={styles.reviewTypography}>
          {user.ratingFactors?.map((r) => startCase(capitalize(r))).join(' - ') || '---'}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography>{user.name}</Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Stack>
          <Typography>{user.createdAt && format('dd-MM-yyyy')(parseISO(user.createdAt))}</Typography>
          <Typography>{user.createdAt && format('hh:mm aa')(parseISO(user.createdAt))}</Typography>
        </Stack>
      </StyledTableCell>

      <StyledTableCell>
        <Typography sx={styles.portionSizeTypography}>{user.portionSize || '--'}</Typography>
      </StyledTableCell>
    </TableRow>
  );
};

export default FeedbackRow;
