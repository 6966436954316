import { Kitchen } from '@calo/types';
import { format } from 'date-fns/fp';
import { PaymentStatus } from 'lib/enums';
import { CateringOrderFilters } from 'lib/interfaces';

export function getDefaultFilters(): CateringOrderFilters {
  return {
    kitchen: Kitchen.SA1,
    status: PaymentStatus.all
  };
}

export const getDate = (date: Date) => format('yyyy-MM-dd')(date);

export const convertStatus = (status?: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.paid:
      return 'CONFIRMED';
    case PaymentStatus.pending:
      return 'PAYMENT_REQUIRED';
    default:
      return undefined;
  }
};

export const convertOrderStatus = (status: string): PaymentStatus => {
  switch (status) {
    case 'CONFIRMED':
      return PaymentStatus.paid;
    case 'PAYMENT_REQUIRED':
      return PaymentStatus.pending;
    default:
      return PaymentStatus.all;
  }
};

export const formatDateTime = (dateString: string) => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return '-';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return `${format('yyyy-MM-dd h:mm a')(date)} (${timeZone})`;
};

export const formatDeliveryHour = (timeRange?: string) => {
  console.log('timeRange', timeRange);
  if (!timeRange) return '';

  const [start, end] = timeRange.split('-').map(Number);
  if (isNaN(start) || isNaN(end)) return '';
  const period = start >= 12 ? 'PM' : 'AM';

  return `${formatHour(start)} - ${formatHour(end)} ${period}`;
};

const formatHour = (hour: number) => {
  const formattedHour = hour % 12 || 12; // Convert 0 to 12 for midnight
  return `${formattedHour} `;
};
