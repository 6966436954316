import { RetailBranch } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { SideDrawer } from 'components';
import { useUserData } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baristaEmailMapping } from 'views/Retail/constant';
import { RetailFilter } from '../../types';

interface SettingsProps {
  onFilter: React.Dispatch<React.SetStateAction<RetailFilter>>;
  filters: RetailFilter;
}

const retailBranchData = Object.values(RetailBranch).map((branch) => ({ label: branch, value: branch }));

const foodCategoryData = [
  { label: 'Froyo', value: 'FROYO' },
  { label: 'Hot Drink', value: 'HOT_DRINK' },
  { label: 'Matcha', value: 'MATCHA' },
  { label: 'Smoothy', value: 'SMOOTHY' },
  { label: 'Salad', value: 'SALAD' },
  { label: 'Cold Drink', value: 'COLD_DRINK' },
  { label: 'Rice Meal', value: 'RICE_MEAL' },
  { label: 'Sandwich', value: 'SANDWICH' },
  { label: 'Calo Meal', value: 'CALO_MEAL' },
  { label: 'Sweet', value: 'SWEET' },
  { label: 'Wrap', value: 'WRAP' },
  { label: 'Snack', value: 'SNACK' }
];

const itemStatusData = [
  { label: 'Active', value: 'Active' },
  { label: 'Disabled', value: 'Disabled' }
];

const itemSizeData = [
  { label: 'R', value: 'R' },
  { label: 'R2', value: 'R2' }
];

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const dispatch = useDispatch();
  const user = useUserData();

  const [localFilters, setLocalFilters] = useState(filters);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setHasChanged(JSON.stringify(localFilters) !== JSON.stringify(filters));
  }, [localFilters, filters]);

  const handleDateChange = (date: Date | null, field: 'startDate' | 'endDate') => {
    setLocalFilters({ ...localFilters, [field]: date });
  };

  const handleSelectChange = (event: SelectChangeEvent<unknown>, field: keyof RetailFilter) => {
    const value = event.target.value;
    setLocalFilters({ ...localFilters, [field]: Array.isArray(value) ? value : value });
  };

  const handleClearFilters = () => {
    setLocalFilters({
      ...filters,
      foodCategory: [],
      status: undefined,
      size: '',
      startDate: undefined,
      endDate: undefined
    });
  };

  const handleApplyFilters = () => {
    onFilter(localFilters);
    dispatch(toggleUISettings());
  };

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5 className="title is-5">Filters</h5>
        <IconButton onClick={() => dispatch(toggleUISettings())}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: 3 }}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Branch</InputLabel>
          <Select value={localFilters.branch} onChange={(e) => handleSelectChange(e, 'branch')} label="Branch">
            {(baristaEmailMapping[user.email]
              ? baristaEmailMapping[user.email].map((e: string) => ({ label: e, value: e }))
              : retailBranchData
            ).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Food Category</InputLabel>
          <Select
            multiple
            value={localFilters.foodCategory ?? []}
            onChange={(e) => handleSelectChange(e, 'foodCategory')}
            label="Food Category"
            renderValue={(selected) => (selected ?? []).join(', ')}
          >
            {foodCategoryData.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Status</InputLabel>
          <Select value={localFilters.status} onChange={(e) => handleSelectChange(e, 'status')} label="Status">
            {itemStatusData.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Size</InputLabel>
          <Select value={localFilters.size} onChange={(e) => handleSelectChange(e, 'size')} label="Size">
            {itemSizeData.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="yyyy-MM-dd"
            value={localFilters.startDate || null}
            onChange={(date) => handleDateChange(date, 'startDate')}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true
                }}
              />
            )}
          />
          <DesktopDatePicker
            label="End Date"
            inputFormat="yyyy-MM-dd"
            value={localFilters.endDate || null}
            onChange={(date) => handleDateChange(date, 'endDate')}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true
                }}
              />
            )}
          />
        </LocalizationProvider>
        <Stack
          direction="row"
          spacing={2}
          sx={{ position: 'absolute', bottom: 48, width: '100%', right: 0, left: 0, px: '16px' }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={handleClearFilters}
            sx={{
              color: 'grey.500',
              borderColor: 'grey.300',
              fontWeight: 500,
              flex: 1,
              '&:hover': {
                borderColor: 'grey.400',
                backgroundColor: 'grey.50'
              }
            }}
          >
            clear filter
          </Button>
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            disabled={!hasChanged}
            sx={{
              backgroundColor: caloTheme.palette.primary500,
              color: 'white',
              fontWeight: 500,
              flex: 1,
              '&:hover': {
                backgroundColor: caloTheme.palette.primary500,
                opacity: 0.9
              },
              '&.Mui-disabled': {
                backgroundColor: 'grey.300',
                color: 'grey.500'
              }
            }}
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </SideDrawer>
  );
};

export default Settings;
