import queryClient from 'lib/queryClient';
import { uniqBy } from 'lodash';
import { PickUpStatus } from '../../types';
import {
  FilterOrdersByStatusParams,
  GetFilteredItemsParams,
  GetOrdersForCurrentTabParams,
  HandleQuerySuccessParams,
  SearchOrderParams
} from './types';

export const searchOrder = ({ order, query }: SearchOrderParams): boolean => {
  if (!query) return true;

  const searchTerm = query.toLowerCase();

  if (order.orderId.toLowerCase().includes(searchTerm)) return true;
  if (order.user.name.toLowerCase().includes(searchTerm)) return true;
  if (order.user.phoneNumber.includes(searchTerm)) return true;
  if (
    order.items.some(
      (item: any) =>
        item.name.en.toLowerCase().includes(searchTerm) || (item.notes && item.notes.toLowerCase().includes(searchTerm))
    )
  )
    return true;
  if (order.notes && order.notes.toLowerCase().includes(searchTerm)) return true;
  return false;
};

export const getFilteredItems = ({ orderList, filters }: GetFilteredItemsParams): any[] => {
  let list = orderList;
  if (filters.pickUpType) {
    list = list.filter((order) => {
      return order.pickUp.pickUpType === filters.pickUpType;
    });
  }
  if (filters.paidOrder === 'true') {
    list = list.filter((order) => {
      return order.pickUp.paymentMethod !== 'loyaltyPoints';
    });
  } else if (filters.paidOrder === 'false') {
    list = list.filter((order) => {
      return order.pickUp.paymentMethod === 'loyaltyPoints';
    });
  }
  return list;
};

export const filterOrdersByStatus = ({
  orderList,
  status,
  filters,
  selectedDate,
  endDate,
  searchQuery
}: FilterOrdersByStatusParams): any[] => {
  const filteredOrders = getFilteredItems({ orderList, filters });
  return (
    filteredOrders?.filter((order) => {
      const orderDate = new Date(order.createdAt);
      const isWithinDateRange = (!selectedDate || orderDate >= selectedDate) && (!endDate || orderDate <= endDate);
      return order.pickUp.status === status && isWithinDateRange && searchOrder({ order, query: searchQuery });
    }) || []
  );
};

export const getOrdersForCurrentTab = ({
  selectedTab,
  orderList,
  filters,
  selectedDate,
  endDate,
  searchQuery
}: GetOrdersForCurrentTabParams): any[] => {
  switch (selectedTab) {
    case 0:
      return filterOrdersByStatus({ orderList, status: PickUpStatus.CREATED, filters, selectedDate, endDate, searchQuery });
    case 1:
      return [
        ...filterOrdersByStatus({
          orderList,
          status: PickUpStatus.READY_FOR_PICK_UP,
          filters,
          selectedDate,
          endDate,
          searchQuery
        }),
        ...filterOrdersByStatus({ orderList, status: PickUpStatus.BARISTA_EN_ROUTE, filters, selectedDate, endDate, searchQuery })
      ];
    case 2:
      return filterOrdersByStatus({ orderList, status: PickUpStatus.PICKED_UP, filters, selectedDate, endDate, searchQuery });
    case 3:
      return filterOrdersByStatus({ orderList, status: PickUpStatus.CANCELLED, filters, selectedDate, endDate, searchQuery });
    default:
      return [];
  }
};

export const handleQuerySuccess = ({
  newData,
  paginationToken,
  fullOrderListLength,
  orderList,
  filters,
  currentBranch,
  setPaginationToken,
  setFullOrderListLength,
  handleShowNotificationCard,
  setOrderList
}: HandleQuerySuccessParams): void => {
  const data = queryClient.getQueryData(['/retail/orders', { branch: filters.branch, token: paginationToken }]) as {
    pickUps: any[];
    token: string;
  };

  if (newData?.token && newData?.token !== paginationToken) {
    setPaginationToken(newData?.token);
  }

  if (!newData?.token) {
    if (!!fullOrderListLength && fullOrderListLength !== orderList.length && currentBranch !== filters.branch) {
      handleShowNotificationCard();
    }
    setFullOrderListLength(orderList.length);
  }

  if (newData.pickUps.length > 0) {
    setOrderList((prevOrderList) => uniqBy([...prevOrderList, ...newData.pickUps], 'id'));
  } else {
    setOrderList((prevOrderList) => uniqBy([...prevOrderList, ...data.pickUps], 'id'));
  }
};
