import { RetailBranch } from '@calo/types';
import { getListWithParams } from 'actions/index';
import { useUserData } from 'lib/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { RetailFilter } from '../../types';
import {
  filterMenuBySearchTerm,
  initializeFilters,
  mapRetailFoodList,
  navigateToEditPage,
  paginateMenu,
  updateMenuItemVisibility,
  updateMenuWithDeletedItem,
  updateRetailMenuState,
  updateSearchParams
} from './helpers';
import { RetailMenuData } from './types';

export const useRetailMenu = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const user = useUserData();
  const branchFromPath = location.pathname.split('/')[2] as RetailBranch;

  const [filters, setFilters] = useState<RetailFilter>(initializeFilters(branchFromPath, user.email, searchParams));

  const { data, isLoading, refetch, error } = useQuery<any, Error>(
    ['retail-menu', { brand: filters.brand, branch: filters.branch }],
    getListWithParams,
    {
      suspense: false,
      initialData: { food: [] },
      onSuccess: () => updateSearchParams(filters, searchParams, history, location)
    }
  );

  const isUnauthorized = (error as any)?.response?.status === 401;
  const [retailMenu, setRetailMenu] = useState<RetailMenuData>({ food: [] });
  const previousBranch = useRef(filters.branch);

  useEffect(() => {
    updateRetailMenuState(filters, data, previousBranch, setRetailMenu);
  }, [filters, data]);

  const handleEditClick = (itemId?: string) => {
    navigateToEditPage(filters, itemId, history);
  };

  const totalItems = retailMenu.food?.length ?? 0;
  const activeItems = retailMenu.food?.filter((item) => !item.retail?.disabled).length ?? 0;

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedSearchTerm(searchTerm), 300);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  const handleSearchChange = (term: string) => setSearchTerm(term);

  const filteredMenu = useMemo(
    () => filterMenuBySearchTerm(retailMenu.food, debouncedSearchTerm),
    [retailMenu.food, debouncedSearchTerm]
  );

  const [isSaving, setIsSaving] = useState(false);

  const retailFoodList = useMemo(() => mapRetailFoodList(data), [data]);

  const handleDeleteItem = async (id: string) => {
    await updateMenuWithDeletedItem(id, retailFoodList, filters, data, refetch, setIsSaving);
  };

  const toggleMenuItemVisibility = async (id: string) => {
    await updateMenuItemVisibility(id, retailFoodList, filters, data, refetch, setIsSaving);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => setCurrentPage(value);

  const paginatedMenu = useMemo(() => paginateMenu(filteredMenu, currentPage, itemsPerPage), [filteredMenu, currentPage]);

  return {
    filters,
    setFilters,
    retailMenu,
    isLoading,
    isUnauthorized,
    handleEditClick,
    totalItems,
    activeItems,
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    handleSearchChange,
    filteredMenu,
    handleDeleteItem,
    isSaving,
    paginatedMenu,
    currentPage,
    handlePageChange,
    itemsPerPage,
    toggleMenuItemVisibility
  };
};
