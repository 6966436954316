import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { CateringOrder } from 'lib/interfaces';
import { useState } from 'react';
import CateringOrderListRow from '../CateringOrderListRow';

interface CateringOrdersListProps {
  data: CateringOrder[];
  isLoading: boolean;
  onSort?: (field: string, order: 'ASC' | 'DESC') => void;
}

export default function CateringOrdersList({ data, isLoading, onSort }: CateringOrdersListProps) {
  const [orderBy, setOrderBy] = useState<'createdAt' | 'date'>('createdAt');
  const [order, setOrder] = useState<'ASC' | 'DESC'>('DESC');

  const handleRequestSort = (property: 'createdAt' | 'date') => {
    const isAsc = orderBy === property && order === 'ASC';
    const newOrder = isAsc ? 'DESC' : 'ASC';
    setOrder(newOrder);
    setOrderBy(property);
    onSort?.(property, newOrder);
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: '14px',
      fontWeight: 600,
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          overflow: 'auto',
          width: '100%',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <TableHead
          sx={{
            backgroundColor: caloTheme.palette.neutral100,
            color: 'black'
          }}
        >
          <TableRow
            sx={{
              backgroundColor: caloTheme.palette.neutral100,
              color: 'black',
              width: '100%'
            }}
          >
            <StyledTableCell sx={{ maxWidth: '90px', width: '90px', minWidth: '90px' }}>Order ID</StyledTableCell>
            <StyledTableCell>User Name</StyledTableCell>
            <StyledTableCell>Quantity</StyledTableCell>
            <StyledTableCell>Price</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === 'createdAt'}
                direction={orderBy === 'createdAt' ? (order.toLowerCase() as any) : 'asc'}
                onClick={() => handleRequestSort('createdAt')}
              >
                Order Date
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === 'date'}
                direction={orderBy === 'date' ? (order.toLowerCase() as any) : 'asc'}
                onClick={() => handleRequestSort('date')}
              >
                Delivery Date
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>Delivery Time</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: '220px', width: '220px', minWidth: '220px' }}>Notes</StyledTableCell>
          </TableRow>
        </TableHead>
        {data.length ? (
          <TableBody>
            {data.map((order) => (
              <CateringOrderListRow key={order.id} order={order} isLoading={isLoading} />
            ))}
          </TableBody>
        ) : (
          <StyledTableCell style={{ border: 0 }} colSpan={10}>
            <Typography
              sx={{
                textAlign: 'center',
                width: 'full',
                fontSize: '24px',
                color: caloTheme.palette.neutral400
              }}
            >
              No Catering Orders for the selected Date
            </Typography>
          </StyledTableCell>
        )}
      </Table>
    </TableContainer>
  );
}
