import { toggleUISettings } from 'actions';
import { Select, Select2, SideDrawer } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { capitalize, compact, omit } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { PortionStatsFilters } from '@calo/dashboard-types';
import { Country, DietType, FoodType, Kitchen } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DeliveryTime } from 'lib/enums';

interface SettingsProps {
  onFilter: (values: PortionStatsFilters & { type: FoodType; size: string[]; onlySandwiches: boolean }) => any;
  filters: PortionStatsFilters & { type: FoodType; size: string[]; onlySandwiches: boolean };
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>

        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country,
              kitchen: data.target.value
                ? (userKitchens.find((k) => k.includes(data.target.value)) as Kitchen)
                : //@ts-ignore
                  ('' as Kitchen) || undefined
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country!)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0 || !filters.country}
        />
        <Select
          label="Delivery Time"
          value={filters.time}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['time']),
              ...(v.value && {
                time: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            {
              value: DeliveryTime.earlyMorning,
              label: 'Early Morning'
            },
            {
              value: DeliveryTime.morning,
              label: 'Morning'
            },
            {
              value: DeliveryTime.evening,
              label: 'Evening'
            }
          ]}
        />
        <div className="field">
          <label className="label">Date range</label>
          <div className="control is-clearfix">
            <DateRangePicker
              clearIcon={null}
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  day: {
                    gte: format('yyyy-MM-dd')(dates[0]),
                    lte: format('yyyy-MM-dd')(dates[1])
                  }
                })
              }
              selectRange
              returnValue="range"
              value={[parseISO(filters.day.gte), parseISO(filters.day.lte)]}
            />
          </div>
        </div>
        <Select
          label="Type"
          value={filters.type}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['type']),
              ...(v.value && {
                type: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(FoodType).map((ft) => ({
              value: ft,
              label: capitalize(ft)
            }))
          ]}
        />
        <Select
          label="Size"
          isMulti
          value={filters.size ?? []}
          onChange={(data: any) => onFilter({ ...filters, size: compact(data.map((s) => s.value)) })}
          options={['XS', 'S', 'M', 'L', 'XL', 'R', 'C', 'CS', 'CD', 'CR', 'CP'].map((s) => ({
            value: s,
            label: s
          }))}
        />
        <Select
          label="Only Sandwiches"
          value={filters.onlySandwiches}
          onChange={(data: any) => onFilter({ ...filters, onlySandwiches: data.value })}
          options={[
            {
              value: true,
              label: 'Yes'
            },
            {
              value: false,
              label: 'No'
            }
          ]}
        />

        <Select
          label="User Diet Type"
          value={filters.dietType}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['dietType']),
              ...(v.value && {
                dietType: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(DietType).map((ft) => ({
              value: ft,
              label: capitalize(ft)
            }))
          ]}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
