import { Box, Button, Card, CardContent, Divider, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CaloLoader from 'components/CaloLoader';
import { Country } from 'lib/calo-types';
import { handleSearch } from 'lib/helpers';
import { caloTheme } from '../../../assets/images/theme/calo';
import SelectMUI from '../../../components/MUI/SelectMUI';
import FoodModifiers from '../../../components/RetailMenuPicker/FoodModifiers';
import { useExactRetailMenu } from './useExactRetailMenu';

const ExactRetailMenu = () => {
  const {
    currentRetailItem,
    setCurrentRetailItem,
    currentFood,
    setFilterName,
    options,
    isLoading,
    retailTagsOptions,
    handleAddItem,
    isEdit,
    handleEditItem,
    isSaving,
    isLoadingFood,
    country
  } = useExactRetailMenu();

  const loyaltyFactor = country === Country.BH ? 0.007 : 0.07;

  const handleShowInRewardsSection = () => {
    if (currentRetailItem?.loyaltyPrice && currentRetailItem?.loyaltyPrice > 0) {
      setCurrentRetailItem({ ...currentRetailItem, loyaltyPrice: 0 });
    } else {
      const loyaltyPrice = Math.round(currentRetailItem.retailPrice / loyaltyFactor);
      setCurrentRetailItem({ ...currentRetailItem, loyaltyPrice });
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {(isSaving || isLoadingFood) && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CaloLoader />
        </Box>
      )}
      <Box sx={{ opacity: isSaving || isLoadingFood ? 0.5 : 1 }}>
        <Card sx={{ padding: 4, margin: -4, pb: 2, borderRadius: 0 }}>
          <Typography variant="body2" gutterBottom>
            Retail &gt; Retail Menu &gt; {isEdit ? 'Edit SKU' : 'Add new SKU'}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h4" gutterBottom>
                {isEdit ? currentFood?.name?.en : 'Add New SKU'}
              </Typography>
              {isEdit && (
                <Typography variant="body1" color="grey">
                  #{currentFood?.id}
                </Typography>
              )}
            </Box>
            <FormControlLabel
              control={<Switch checked={!currentRetailItem?.disabled} />}
              label="Active"
              sx={{ marginBottom: 2 }}
              onChange={() => setCurrentRetailItem({ ...currentRetailItem, disabled: !currentRetailItem?.disabled })}
            />
          </Box>
        </Card>
        <Card variant="elevation" sx={{ marginBottom: 3, overflow: 'visible', zIndex: 1000, mt: 7 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Basic information
            </Typography>
            <Divider sx={{ marginBottom: 3, mt: 1, mx: -2 }} />
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
              <Box sx={{ width: '33%' }}>
                <SelectMUI
                  onChange={(data: any) => {
                    setCurrentRetailItem({ ...currentRetailItem, id: data.value });
                  }}
                  onInputChange={(data: string, action: any) =>
                    setFilterName && handleSearch({ text: data, action, name: setFilterName })
                  }
                  isDisabled={isEdit}
                  className="leading-10 flex-1"
                  placeholder={isEdit ? currentFood?.name?.en : 'SKU Name*'}
                  data-test="addon-picker-addon-select"
                  options={options}
                  isLoading={isLoading}
                  value={currentRetailItem?.id}
                />
              </Box>
              <Box sx={{ width: '33%' }}>
                <SelectMUI
                  isMulti
                  className="leading-10 flex-1"
                  placeholder="Retail category*"
                  options={retailTagsOptions}
                  value={currentRetailItem?.retailTagList ? currentRetailItem.retailTagList : []}
                  onChange={(selectedOptions: any) => {
                    const selectedValues = Array.isArray(selectedOptions) ? selectedOptions.map((option) => option.value) : [];
                    setCurrentRetailItem({
                      ...currentRetailItem,
                      retailTagList: selectedValues
                    });
                  }}
                />
              </Box>
              <Box sx={{ width: '33%' }}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Price*"
                  value={currentRetailItem?.retailPrice}
                  onChange={(e) => setCurrentRetailItem({ ...currentRetailItem, retailPrice: +e.target.value })}
                />
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={currentRetailItem?.swapAvailable}
                  onChange={() =>
                    setCurrentRetailItem({ ...currentRetailItem, swapAvailable: !currentRetailItem?.swapAvailable })
                  }
                />
              }
              label="Show in Meal Subscription"
            />
            <Typography variant="body2" sx={{ backgroundColor: '#F5F5F5', mx: -2, p: 2, mt: 3, fontWeight: 'bold' }} gutterBottom>
              Rewards Section
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mt: 2, height: '60px' }}>
              <FormControlLabel
                control={<Switch checked={!!currentRetailItem?.loyaltyPrice} onChange={handleShowInRewardsSection} />}
                label="Show in Rewards section"
              />
              {!!currentRetailItem?.loyaltyPrice && (
                <TextField
                  type="number"
                  placeholder="Points*"
                  label="Points"
                  value={currentRetailItem?.loyaltyPrice}
                  onChange={(e) => {
                    const value = Math.floor(+e.target.value);
                    if (value > 0) {
                      setCurrentRetailItem({ ...currentRetailItem, loyaltyPrice: value });
                    }
                  }}
                />
              )}
            </Box>
          </CardContent>
        </Card>

        <Card variant="elevation" sx={{ marginBottom: 3, overflow: 'visible' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Availability
            </Typography>
            <Divider sx={{ marginBottom: 3, mt: 1, mx: -2 }} />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Starting Date"
                  value={currentRetailItem?.startDate || null}
                  className="w-full"
                  onChange={(newValue) => setCurrentRetailItem({ ...currentRetailItem, startDate: newValue! })}
                  renderInput={(params: TextFieldProps) => <TextField {...params} />}
                />
                <DatePicker
                  label="Ending Date"
                  value={currentRetailItem?.endDate || null}
                  className="w-full"
                  onChange={(newValue) => setCurrentRetailItem({ ...currentRetailItem, endDate: newValue! })}
                  renderInput={(params: TextFieldProps) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </CardContent>
        </Card>

        <Card variant="elevation" sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Modifiers
            </Typography>
            <Divider sx={{ marginBottom: 3, mt: 1, mx: -2 }} />
            <FoodModifiers
              currentRetailItem={currentRetailItem}
              setCurrentRetailItem={setCurrentRetailItem}
              currentFood={currentFood}
            />
          </CardContent>
        </Card>

        <Button
          variant="contained"
          sx={{ backgroundColor: caloTheme.palette.primary500, px: 3, color: 'white' }}
          onClick={isEdit ? handleEditItem : handleAddItem}
        >
          {isEdit ? 'Update SKU' : 'Create SKU'}
        </Button>
      </Box>
    </Box>
  );
};

export default ExactRetailMenu;
