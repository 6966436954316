import { forwardRef, useState } from 'react';

import { FormikErrors } from 'formik';

import { KitchenLogType, Permission, PrototypeAction, UpdateFoodReq } from '@calo/dashboard-types';
import { Box, Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { getImageUploadLink, updateFood } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { format, parseISO } from 'date-fns/fp';
import { ImageType } from 'lib/enums';
import { hasValidCharactersForLanguage } from 'lib/helpers';
import { getImageUrl, ImageVariant } from 'lib/helpers/getImageUrl';
import { Food, UpdateFoodReqWithIndex } from 'lib/interfaces';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ImageUploaderMUI, InputMUI } from '../';

interface MealInformationProps {
  roles: any;
  food: Food;
  showOwner?: boolean;
  isDisabled: boolean;
  values: UpdateFoodReq;
  errors: FormikErrors<UpdateFoodReqWithIndex>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const MealInformation = forwardRef<HTMLDivElement, MealInformationProps>(
  ({ values, errors, food, isDisabled, roles, showOwner, setFieldValue }: MealInformationProps, ref) => {
    const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });
    const [newAttachmentId, setNewAttachmentId] = useState(uuid());
    const { mutateAsync: updateMutation } = useMutation(updateFood);

    const displayImage = getImageUrl(food.imageUrl, ImageVariant.square1x);

    const handleInformationFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
      const allowSpecialChar = field.includes('description');
      if (hasValidCharactersForLanguage(data, lang, allowSpecialChar) || data === '') {
        setFieldValue(field, data);
        setLangAlert({ ...langAlert, [`${lang}`]: false, value: field });
      } else {
        setFieldValue(field, '');
        setLangAlert({ ...langAlert, [`${lang}`]: true, value: field });
      }
    };

    const createInfo = food.prototypeActions?.find((action) => action.type === PrototypeAction.create);

    const handleImageUploaded = async () => {
      try {
        await updateMutation({ id: food.id, attachmentId: newAttachmentId });
        setNewAttachmentId(uuid());
      } catch (error) {
        console.error('Error updating attachmentId:', error);
        toast('Error updating attachment id', { type: 'error', autoClose: 2000 });
      }
    };

    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        ref={ref}
        sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '8px', boxShadow: 2 }}
      >
        <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Stack sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flexStart' }}>
            <Typography
              variant="h5"
              sx={{
                color: caloTheme.palette.neutral900,
                textTransform: 'capitalize',
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '125%'
              }}
            >
              Meal Information
            </Typography>
            {food.createdAt && (
              <Typography
                sx={{
                  color: caloTheme.palette.textSecondary,
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '125%'
                }}
              >
                {`Created on ${format('dd/MM/yyyy')(parseISO(food.createdAt))}`}
              </Typography>
            )}
          </Stack>
          {showOwner && (
            <Typography sx={{ color: caloTheme.palette.textSecondary, fontSize: '14px', fontWeight: 400, lineHeight: '125%' }}>
              Created by: {createInfo?.actor.name}
            </Typography>
          )}
        </Stack>

        <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />

        <Stack
          alignSelf={'stretch'}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: '20px', pr: 0, width: '100%' }}
        >
          <Box sx={{ mt: '-22px' }}>
            <ImageUploaderMUI
              key={food.id}
              values={{ name: food.name.en, id: food.id, type: KitchenLogType.food }}
              uploadLink={() => getImageUploadLink(`food/${newAttachmentId}`)}
              image={displayImage}
              disabled={roles.includes(Permission.UPLOAD_IMAGE)}
              id={food.id}
              imageType={ImageType.FOOD}
              onImageUploaded={handleImageUploaded}
            />
          </Box>
          <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ width: '40%', ml: 2 }}>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
              <InputMUI
                label="Meal Name (English)"
                name="name.en"
                value={values.name?.en}
                debounce
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(data) => {
                  handleInformationFieldChange('name.en', data.target.value, 'EN');
                }}
                error={!!(errors.name as any)?.en || (langAlert.EN && langAlert.value === 'name.en')}
                disabled={isDisabled}
                sx={{
                  width: '100%',
                  mb:
                    (langAlert.EN && langAlert.value === 'name.en') ||
                    (values.name?.en && values.name.en.length > 0 && values.name.en.length < 3)
                      ? 0
                      : '20px'
                }}
                inputProps={{ style: { borderRadius: 8 } }}
              />
              <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.EN && langAlert.value === 'name.en' && 'letters should be in English only'}
                {values.name?.en && values.name.en.length > 0 && values.name.en.length < 3 && 'Minimum 3 characters'}
              </FormHelperText>
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
              <InputMUI
                label="Description (English - Sticker)"
                name="description.en"
                value={values.description!.en}
                debounce
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(data) => handleInformationFieldChange('description.en', data.target.value, 'EN')}
                disabled={isDisabled}
                error={!!(errors.description as any)?.en || (langAlert.EN && langAlert.value === 'description.en')}
                sx={{ width: '100%', mb: langAlert.EN && langAlert.value === 'description.en' ? 0 : '20px' }}
                inputProps={{ style: { borderRadius: 8 }, datatest: 'foodEnDescriptionTextField' }}
              />
              <FormHelperText id="description.en-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.EN && langAlert.value === 'description.en' && 'Letters should be in English only'}
              </FormHelperText>
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
              <InputMUI
                label="Long Description (English)"
                name="longDescription.en"
                value={values.longDescription?.en}
                debounce
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(data) => setFieldValue('longDescription.en', data.target.value)}
                disabled={isDisabled}
                error={!!(errors.longDescription as any)?.en || (langAlert.EN && langAlert.value === 'longDescription.en')}
                sx={{ width: '100%', mb: langAlert.EN && langAlert.value === 'longDescription.en' ? 0 : '20px' }}
                inputProps={{ style: { borderRadius: 8 } }}
                rows={4}
                multiline
              />
              <FormHelperText id="longDescription.en-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.EN && langAlert.value === 'longDescription.en' && 'Letters should be in English only'}
              </FormHelperText>
            </Stack>
          </Stack>
          <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ width: '40%', mx: 2 }}>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
              <InputMUI
                label="Meal Name (Arabic)"
                name="name.ar"
                value={values.name?.ar}
                debounce
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(data) => handleInformationFieldChange('name.ar', data.target.value, 'AR')}
                error={!!(errors.name as any)?.ar || (langAlert.AR && langAlert.value === 'name.ar')}
                disabled={isDisabled}
                sx={{
                  width: '100%',
                  mb:
                    (langAlert.AR && langAlert.value === 'name.ar') ||
                    (values.name?.ar && values.name.ar.length > 0 && values.name.ar.length < 3)
                      ? 0
                      : '20px'
                }}
                inputProps={{ style: { borderRadius: 8 } }}
              />
              <FormHelperText id="name.ar-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.AR && langAlert.value === 'name.ar' && 'Letters should be in Arabic only'}
                {values.name?.ar && values.name.ar.length > 0 && values.name.ar.length < 3 && 'Minimum 3 characters'}
              </FormHelperText>
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
              <InputMUI
                label="Description (Arabic - Sticker)"
                name="description.ar"
                value={values.description!.ar}
                debounce
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(data) => handleInformationFieldChange('description.ar', data.target.value, 'AR')}
                error={!!(errors.description as any)?.ar || (langAlert.AR && langAlert.value === 'description.ar')}
                disabled={isDisabled}
                sx={{ width: '100%', mb: langAlert.AR && langAlert.value === 'description.ar' ? 0 : '20px' }}
                inputProps={{ style: { borderRadius: 8 } }}
              />
              <FormHelperText id="description.ar-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.AR && langAlert.value === 'description.ar' && 'Letters should be in Arabic only'}
              </FormHelperText>
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
              <InputMUI
                label="Long Description (Arabic)"
                name="longDescription.ar"
                value={values.longDescription?.ar}
                debounce
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(data) => setFieldValue('longDescription.ar', data.target.value)}
                error={!!(errors.longDescription as any)?.ar || (langAlert.AR && langAlert.value === 'longDescription.ar')}
                disabled={isDisabled}
                sx={{ width: '100%', mb: langAlert.AR && langAlert.value === 'longDescription.ar' ? 0 : '20px' }}
                inputProps={{ style: { borderRadius: 8 } }}
                rows={4}
                multiline
              />
              <FormHelperText id="longDescription.ar-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.AR && langAlert.value === 'longDescription.ar' && 'Letters should be in Arabic only'}
              </FormHelperText>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }
);

export default MealInformation;
