import { IngredientStatsReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material';
import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { format } from 'date-fns/fp';
import { resolveCountryFromKitchen } from 'lib';
import history from 'lib/history';
import { useUserKitchens } from 'lib/hooks';
import { IngredientStats } from 'lib/interfaces';
import { flatten, sortBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import TableStatsHeaderSection from '../TableStatsHeaderSection';
import ComponentsDrawer from './ComponentsDrawer';
import IngredientStatsHeaderCard from './IngredientStatsHeaderCard';
import Settings from './Settings';
import { grouppedAllIngredients } from './helpers';
const KitchenIngredientStats = () => {
  const location = useLocation();
  const userKitchen = useUserKitchens();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<IngredientStatsReq>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    day: {
      gte: format('yyyy-MM-dd')(Date.now()),
      lte: format('yyyy-MM-dd')(Date.now())
    },
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const [filtersCheck, setFiltersCheck] = useState({ subscriptions: true, businesses: false, charity: false, catering: false });
  const [showAllUnits, setShowAllUnits] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ingredient, setIngredient] = useState<IngredientStats | undefined>(undefined);
  const [showDetailedView, setShowDetailedView] = useState(false);

  const { data: statsData, isLoading } = useQuery<
    any,
    Error,
    { data: IngredientStats[]; charity: IngredientStats[]; business: IngredientStats[]; catering: IngredientStats[] }
  >(['/stats/kitchen-ingredient', filters], getListWithParams, {
    onSuccess: () => {
      searchParams.set('filters', JSON.stringify(filters));
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
  });

  const filteredStatsData: IngredientStats[] = useMemo(() => {
    const data: IngredientStats[][] = [];
    if (filtersCheck.subscriptions) {
      data.push(statsData ? statsData?.data : []);
    }
    if (filtersCheck.businesses) {
      data.push(statsData ? statsData?.business : []);
    }
    if (filtersCheck.charity) {
      data.push(statsData ? statsData?.charity : []);
    }
    if (filtersCheck.catering) {
      data.push(statsData ? statsData?.catering : []);
    }

    return flatten(data);
  }, [filtersCheck, statsData]);

  const summedComponentData = Object.values(grouppedAllIngredients(filteredStatsData));

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      backgroundColor: caloTheme.palette.neutral50
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'capitalize'
    }
  }));

  const calculatedQuantity = (quantity: number, weight?: number) => Math.ceil(quantity / (showAllUnits && weight ? weight : 1));

  return (
    <>
      <IngredientStatsHeaderCard
        summedComponentData={summedComponentData}
        isLoading={isLoading}
        filters={filters}
        filtersCheck={filtersCheck}
        showDetailedView={showDetailedView}
      />
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: '14px',
          border: 'none',
          borderRadius: '16px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          <TableStatsHeaderSection
            filtersCheck={filtersCheck}
            setFiltersCheck={setFiltersCheck}
            title="Ingredients"
            setSwitchUnit={setShowAllUnits}
            switchUnit={showAllUnits}
            showToggleSwitch={true}
            showDetailedView={showDetailedView}
            setShowDetailedView={setShowDetailedView}
          />
          {isLoading ? (
            <Stack sx={{ width: '100%', justifyContent: 'center' }}>
              <CaloLoader />
            </Stack>
          ) : (
            <div style={{ height: '70vh', overflow: 'auto' }}>
              <Table
                stickyHeader={true}
                sx={{
                  height: '100%',
                  overflowY: 'auto',
                  width: '100%',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'column'
                  }
                }}
              >
                <TableHead
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    flexWrap: 0,
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <TableRow
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      width: '100%',
                      flexWrap: 0,
                      justifyContent: 'space-between'
                    }}
                  >
                    <StyledTableCell style={{ width: '20%', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '20%' }}>Internal Name</StyledTableCell>
                    {showDetailedView && (
                      <>
                        <StyledTableCell style={{ width: '15%' }}>Quantity</StyledTableCell>
                        <StyledTableCell style={{ width: '15%' }}>Quantity After Prep</StyledTableCell>
                        <StyledTableCell style={{ width: '15%' }}>Special Requests Quantity</StyledTableCell>
                        <StyledTableCell style={{ width: '15%' }}>Special Requests Quantity After Prep</StyledTableCell>
                        <StyledTableCell style={{ width: '12%' }}>Sub-component Quantity</StyledTableCell>
                        <StyledTableCell style={{ width: '12%' }}>Sub-component Quantity after prep</StyledTableCell>
                        <StyledTableCell style={{ width: '12%' }}>Removed Quantity</StyledTableCell>
                        <StyledTableCell style={{ width: '12%' }}>Removed Quantity after prep</StyledTableCell>
                      </>
                    )}
                    <StyledTableCell style={{ width: '15%' }}>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                        <Tooltip
                          title="Quantity + Sub-component Quantity + Special Requests Quantity - Removed Quantity"
                          placement="top-end"
                          sx={{
                            borderRadius: 10,
                            padding: 0.2,
                            ':hover': { cursor: 'pointer' }
                          }}
                        >
                          <InfoOutlined color={'info'} fontSize="medium" />
                        </Tooltip>
                        <Typography
                          sx={{
                            color: caloTheme.palette.neutral900,
                            fontSize: '12px',
                            lineHeight: '14px',
                            fontFamily: 'Roboto',
                            fontWeight: 600
                          }}
                        >
                          Total Quantity
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '15%' }}>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                        <Tooltip
                          title="Quantity After Prep + Sub-component Quantity After Prep + Special Requests Quantity After Prep - Removed Quantity After Prep"
                          placement="top-end"
                          sx={{
                            borderRadius: 10,
                            padding: 0.2,
                            ':hover': { cursor: 'pointer' }
                          }}
                        >
                          <InfoOutlined color={'info'} fontSize="medium" />
                        </Tooltip>
                        <Typography
                          sx={{
                            color: caloTheme.palette.neutral900,
                            fontSize: '12px',
                            lineHeight: '14px',
                            fontFamily: 'Roboto',
                            fontWeight: 600
                          }}
                        >
                          Total Quantity After Prep
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '12%' }}>Measurement Unit</StyledTableCell>
                  </TableRow>
                </TableHead>
                {summedComponentData && summedComponentData.length === 0 ? (
                  <StyledTableCell style={{ border: 0 }} colSpan={6}>
                    <Typography
                      sx={{
                        mt: 3,
                        textAlign: 'center',
                        width: 'full',
                        fontSize: '24px',
                        color: caloTheme.palette.neutral400
                      }}
                    >
                      No Stats
                    </Typography>
                  </StyledTableCell>
                ) : (
                  <TableBody>
                    {summedComponentData &&
                      sortBy(summedComponentData, 'name.en')?.map((row) => {
                        return (
                          <TableRow key={uuid()}>
                            <StyledTableCell style={{ fontWeight: 600 }}>
                              <Button
                                variant="text"
                                sx={{
                                  color: caloTheme.palette.hyperlink,
                                  textTransform: 'capitalize',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  lineHeight: '19px'
                                }}
                                onClick={() => {
                                  setIngredient(row);
                                  setIsOpen(true);
                                }}
                              >
                                {row.name.en}
                              </Button>
                            </StyledTableCell>
                            <StyledTableCell style={{ fontWeight: 400 }}>{row.internalName}</StyledTableCell>
                            {showDetailedView && (
                              <>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity(row.quantity - row.specialRequestsQuantity, row.weight)}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity((row.quantity - row.specialRequestsQuantity) / row.wastage, row.weight)}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity(row.specialRequestsQuantity, row.weight)}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity(row.specialRequestsQuantity / row.wastage, row.weight)}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity(row.childQuantityBeforePrep, row.weight)}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity(row.childQuantityBeforePrep / row.wastage, row.weight)}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity(row.removedQuantity, row.weight)}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 400 }}>
                                  {calculatedQuantity(row.removedQuantity / row.wastage, row.weight)}
                                </StyledTableCell>
                              </>
                            )}
                            <StyledTableCell style={{ fontWeight: 400 }}>
                              {calculatedQuantity(row.quantity - row.removedQuantity + row.childQuantityBeforePrep, row.weight)}
                            </StyledTableCell>
                            <StyledTableCell style={{ fontWeight: 400 }}>
                              {calculatedQuantity(
                                (row.quantity - row.removedQuantity + row.childQuantityBeforePrep) / row.wastage,
                                row.weight
                              )}
                            </StyledTableCell>

                            <StyledTableCell style={{ fontWeight: 400, textTransform: 'none' }}>
                              {showAllUnits ? row.measurementUnit : 'g'}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </div>
          )}
        </Box>
      </Card>
      <Settings onFilter={setFilters} filters={filters} />
      <ComponentsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        ingredient={ingredient}
        filters={filters}
        showAllUnits={showAllUnits}
      />
    </>
  );
};

export default KitchenIngredientStats;
