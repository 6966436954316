import { Button, CardMedia, Stack, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import IngredientList from 'components/CustomFoodPopup/CustomMealForm/Shared/IngredientList';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import Select2 from 'components/Select2';
import { getImageUrl, ImageVariant } from 'lib/helpers/getImageUrl';
import { FoodWithPosition, MenuFood } from 'lib/interfaces';
import { compact, flatten, groupBy } from 'lodash-es';
import { useState } from 'react';
import { MacrosChip } from './MacrosChip';

interface FoodListItemProps {
  food: MenuFood;
  isLastItem: boolean;
  selectedFood: FoodWithPosition;
  allMenuFood: MenuFood[];
  handleClick: (value: MenuFood) => Promise<void>;
}

const FoodListItem = ({ food, isLastItem, allMenuFood, selectedFood, handleClick }: FoodListItemProps) => {
  const [selectedSize, setSelectedSize] = useState({ size: food.size, foodId: food.id, foodName: food.name.en });
  const sameMealSize = flatten(Object.values(groupBy(allMenuFood, 'name.en')));
  const displayImage = getImageUrl(food.imageUrl, ImageVariant.square1x);

  const handleSizeChange = (size: string, allSizes: MenuFood[]) => {
    const selectedMealData = flatten(compact(allSizes.filter((r) => r.size === size)));
    setSelectedSize({ size: selectedMealData[0].size, foodId: selectedMealData[0].id, foodName: selectedMealData[0].name.en });
  };

  const handleFoodSize = (foodName: MenuFood) => {
    const allSizes = compact(sameMealSize.map((r) => r.name.en === foodName.name.en && r));
    return (
      <Select2
        value={selectedSize.size}
        name="size"
        className="w-20 mt-2 z-0"
        onChange={(data) => handleSizeChange(data.target.value, allSizes)}
        options={Object.values(allSizes).map((meal) => ({
          value: meal.size,
          label: meal.size
        }))}
      />
    );
  };

  const handleSelectMeal = (food: MenuFood, selectedSize: any) => {
    if (selectedSize.foodName === food.name.en && selectedSize.size !== food.size) {
      handleClick(selectedSize);
    } else {
      handleClick(food);
    }
  };

  const isSelectedMeal = selectedFood.name.en === food.name.en && selectedFood.size === selectedSize.size;

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        paddingY: '10px',
        borderBottomWidth: '1px',
        alignItems: 'flex-start',
        borderColor: isLastItem ? 'transparent' : caloTheme.palette.neutral100
      }}
    >
      <Stack sx={{ marginRight: '10px', width: '10%' }}>
        <CardMedia
          component="img"
          image={displayImage}
          alt="placeholder"
          sx={{ width: 80, maxHeight: 80, height: 80, objectFit: 'cover', borderRadius: '4px' }}
          onError={(e: any) => {
            e.target.src = noImagePlaceholder;
          }}
        />
      </Stack>
      <Stack sx={{ width: '50%', marginRight: '20px' }}>
        <Stack sx={{ flexDirection: 'row', gap: '10px', alignItems: 'center', marginBottom: '2px' }}>
          <Typography sx={{ fontWeight: 600 }}>{food.name.en}</Typography>
          <Stack sx={{ flexDirection: 'row', gap: '6px' }}>
            {food.tags?.map((tag) => <FoodDietTypeTags key={tag} tag={tag} />)}
          </Stack>
        </Stack>
        <IngredientList ingredients={food.ingredients} sx={{ fontSize: '12px' }} />
        <Stack sx={{ flexDirection: 'row', my: 2, gap: '10px' }}>
          {Object.entries(food.macros ?? {}).map(([mealMacrosKey, mealMacrosValue]) => (
            <Stack key={mealMacrosKey}>
              <MacrosChip macrosKey={mealMacrosKey} macrosValue={mealMacrosValue} />
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack sx={{ marginLeft: 'auto', marginRight: '16px' }}>{handleFoodSize(food)}</Stack>

      <Button
        variant="outlined"
        aria-label="show-meal-macros"
        disabled={isSelectedMeal}
        sx={{
          marginTop: '5px',
          fontWeight: 600,
          fontSize: '16px',
          paddingY: '8px',
          boxShadow: 'none',
          borderRadius: '8px',
          textTransform: 'none',
          fontFamily: caloTheme.typography.fontFamily,
          borderColor: caloTheme.palette.neutral900,
          color: isSelectedMeal ? caloTheme.palette.white : caloTheme.palette.neutral900,
          backgroundColor: isSelectedMeal ? caloTheme.palette.neutral900 : caloTheme.palette.white,
          '&:hover': {
            borderColor: caloTheme.palette.neutral900,
            backgroundColor: isSelectedMeal ? caloTheme.palette.neutral900 : caloTheme.palette.neutral100,
            color: isSelectedMeal ? caloTheme.palette.white : caloTheme.palette.neutral900
          },
          ':disabled': {
            backgroundColor: 'transparent',
            color: caloTheme.palette.neutral500
          },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            justifyItems: 'center',
            margin: 'auto',
            marginTop: 4,
            width: 'auto'
          }
        }}
        onClick={() => (isSelectedMeal ? null : handleSelectMeal(food, selectedSize))}
      >
        {isSelectedMeal ? 'Selected' : 'Select Meal'}
      </Button>
    </Stack>
  );
};

export default FoodListItem;
