import { CreateBulkCouponsClientReq } from '@calo/dashboard-types';
import { createBulkCoupons } from 'actions/coupons';
import { Routes } from 'lib/enums';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import BulkCouponsForm from './BulkCouponsForm';

const CreateBulkCoupons = () => {
  const { mutateAsync: createMutation } = useMutation(createBulkCoupons);

  const history = useHistory();

  const handleNewCoupon = async (values: Omit<CreateBulkCouponsClientReq, 'id'>) => {
    await createMutation(values);
    history.replace(Routes.couponsList);
  };

  return (
    <div className=" overflow-visible ">
      <BulkCouponsForm onSubmit={handleNewCoupon} />
    </div>
  );
};
export default CreateBulkCoupons;
