import { LocalCafe, MoreVert } from '@mui/icons-material';
import { Chip, MenuItem, Select, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import { Divider, IconButton } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { retailMenuDrinkItems } from 'lib/constants';
import { useMemo } from 'react';
import { PricingService } from 'services';
import { OrderItemType, PickUpStatus, PickUpType } from '../../types';

export const pickUpTimeSVG = {
  [PickUpType.CAR_HOP]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M14.19 3.7575C14.04 3.315 13.62 3 13.125 3H4.875C4.38 3 3.9675 3.315 3.81 3.7575L2.3325 8.01C2.28 8.1675 2.25 8.3325 2.25 8.505V13.875C2.25 14.4975 2.7525 15 3.375 15C3.9975 15 4.5 14.4975 4.5 13.875V13.5H13.5V13.875C13.5 14.49 14.0025 15 14.625 15C15.24 15 15.75 14.4975 15.75 13.875V8.505C15.75 8.34 15.72 8.1675 15.6675 8.01L14.19 3.7575ZM4.875 11.25C4.2525 11.25 3.75 10.7475 3.75 10.125C3.75 9.5025 4.2525 9 4.875 9C5.4975 9 6 9.5025 6 10.125C6 10.7475 5.4975 11.25 4.875 11.25ZM13.125 11.25C12.5025 11.25 12 10.7475 12 10.125C12 9.5025 12.5025 9 13.125 9C13.7475 9 14.25 9.5025 14.25 10.125C14.25 10.7475 13.7475 11.25 13.125 11.25ZM3.75 7.5L4.7025 4.635C4.8075 4.335 5.0925 4.125 5.415 4.125H12.585C12.9075 4.125 13.1925 4.335 13.2975 4.635L14.25 7.5H3.75Z"
        fill="black"
        fillOpacity="0.56"
      />
    </svg>
  ),
  [PickUpType.SELF_PICKUP]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
      <path
        d="M10.5 3.5H9C9 1.8425 7.6575 0.5 6 0.5C4.3425 0.5 3 1.8425 3 3.5H1.5C0.675 3.5 0 4.175 0 5V14C0 14.825 0.675 15.5 1.5 15.5H10.5C11.325 15.5 12 14.825 12 14V5C12 4.175 11.325 3.5 10.5 3.5ZM4.5 6.5C4.5 6.9125 4.1625 7.25 3.75 7.25C3.3375 7.25 3 6.9125 3 6.5V5H4.5V6.5ZM6 2C6.825 2 7.5 2.675 7.5 3.5H4.5C4.5 2.675 5.175 2 6 2ZM9 6.5C9 6.9125 8.6625 7.25 8.25 7.25C7.8375 7.25 7.5 6.9125 7.5 6.5V5H9V6.5Z"
        fill="black"
        fillOpacity="0.56"
      />
    </svg>
  ),
  [PickUpType.DINE_IN]: <LocalCafe sx={{ fontSize: '16px', mt: '1px', color: 'gray' }} />
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    justifyContent: 'space-between'
  }
}));

interface OrderTableRowProps {
  order: any;
  loadingItemIds: string[];
  isFetching: boolean;
  isLoading: boolean;
  isCancellationAllowed: boolean;
  selectedTabStatus: PickUpStatus | '';
  handleActions: ({ order, anchorEl }: { order: any; anchorEl: HTMLElement }) => void;
  onChangeStatus: (pickUpId: string, status: PickUpStatus) => void;
}

const formatDate = format('dd MMM yyyy');
const formatTime = format('hh:mm a');
const getDateFromString = (dateString: string) => new Date(dateString);

const formatDateTime = (dateString: string) => formatDate(getDateFromString(dateString));
const formatTimeOnly = (dateString: string) => formatTime(getDateFromString(dateString));

const activeSelectStyle = {
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: caloTheme.palette.primary500
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: caloTheme.palette.primary500
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: caloTheme.palette.primary500
  },
  '& .MuiSelect-icon': {
    color: caloTheme.palette.primary500
  }
};

const OrderTableRow = ({
  order,
  loadingItemIds,
  isFetching,
  isLoading,
  isCancellationAllowed,
  selectedTabStatus,
  handleActions,
  onChangeStatus
}: OrderTableRowProps) => {
  const isDisabled = useMemo(() => {
    return isFetching || isLoading || order.pickUp.status === PickUpStatus.PICKED_UP;
  }, [isFetching, isLoading, order.pickUp.status]);

  const currency = order.items?.[0]?.price.currency;
  const totalAmount = order.items.reduce((acc: number, item: any) => {
    if (item.itemType === OrderItemType.LOYALTY) return acc;
    acc += item.price.amount;
    return acc;
  }, 0);

  return (
    <TableRow
      sx={{
        opacity: loadingItemIds.includes(order.pickUp.id) ? 0.5 : 1,
        pointerEvents: loadingItemIds.includes(order.pickUp.id) ? 'none' : 'auto',
        borderBottom: '1px solid var(--divider, rgba(0, 0, 0, 0.12))'
      }}
    >
      <StyledTableCell>{order.orderId}</StyledTableCell>
      <StyledTableCell>
        {order.user.name}
        <br />
        {order.user.phoneNumber}
        <br />
        {order.pickUp.customerArrivedAt && (
          <Chip label="Outside" variant="filled" sx={{ color: 'white', backgroundColor: 'rgba(239, 108, 0, 1)' }} />
        )}
      </StyledTableCell>
      <StyledTableCell>
        {formatDateTime(order.createdAt)}
        <br />
        {formatTimeOnly(order.createdAt)}
      </StyledTableCell>
      <StyledTableCell style={{ maxWidth: '250px' }}>
        {formatTimeOnly(order.pickUp.pickUpAt)}
        <br />
        <div className="flex">
          {pickUpTimeSVG[order.pickUp.pickUpType]}
          <b className="ml-1">
            {order.pickUp.pickUpType === PickUpType.SELF_PICKUP
              ? 'Pick-up'
              : order.pickUp.pickUpType === PickUpType.DINE_IN
                ? 'Dine-in'
                : 'Carhop'}
          </b>
        </div>
        {order.pickUp.pickUpType === PickUpType.CAR_HOP && (
          <div className=" mt-2 flex flex-col">
            <b className="text-xs text-gray-400">Car details</b>
            <div>{order.pickUp.vehiclePlateNumber}</div>
          </div>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {order.pickUp.status === PickUpStatus.CANCELLED ? (
          <Chip label="Cancelled" color="error" variant="outlined" />
        ) : (
          <Select
            value={order.pickUp.status}
            onChange={(e) => onChangeStatus(order.pickUp.id, e.target.value as PickUpStatus)}
            disabled={isDisabled}
            variant="outlined"
            sx={{
              color: caloTheme.palette.primary500,
              fontSize: '0.875rem',
              borderRadius: '100px',
              height: '35px',
              fontWeight: 500,
              ...(!isDisabled && activeSelectStyle)
            }}
          >
            {Object.values(PickUpStatus).map((status) =>
              status === PickUpStatus.CANCELLED ? null : (
                <MenuItem
                  key={status}
                  value={status}
                  disabled={
                    (order.pickUp.status === PickUpStatus.CREATED && status === PickUpStatus.PICKED_UP) ||
                    status === PickUpStatus.BARISTA_EN_ROUTE
                  }
                >
                  {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
                </MenuItem>
              )
            )}
          </Select>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {order.items.map((item: any) => {
          const isDrinkCategory = item.category ? retailMenuDrinkItems[item.category] : true;
          const itemSize = isDrinkCategory && item.size ? `(${item.size})` : '';
          const itemType = item.itemType === OrderItemType.LOYALTY ? '(Loyalty)' : '';
          return (
            <div key={item.id}>
              <div>
                <strong style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{item.quantity} x</strong>{' '}
                {String(item.name!.en?.endsWith(' R') ? item.name!.en.slice(0, -1) : item.name!.en)} {itemSize} {itemType}
              </div>
              {item.notes && (
                <div>
                  <div>{String(item.name.en)}:</div>
                  {item.notes.split('\n').map((note: string, idx: number) => (
                    <div key={idx}>{note}</div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
        {totalAmount > 0 && (
          <>
            <Divider sx={{ margin: '10px 0' }} />
            <div>
              <strong>
                Total: {PricingService.roundAmount(totalAmount, currency)} {currency} ({order.pickUp.paymentMethod})
              </strong>
            </div>
          </>
        )}
      </StyledTableCell>
      <StyledTableCell>{order.notes}</StyledTableCell>
      {selectedTabStatus === PickUpStatus.CANCELLED && <StyledTableCell>{order.pickUp.cancellationReason}</StyledTableCell>}
      {isCancellationAllowed && (
        <StyledTableCell>
          <IconButton
            id={`order-${order.orderId}-actions`}
            onClick={(event) => handleActions({ order, anchorEl: event.currentTarget })}
          >
            <MoreVert />
          </IconButton>
        </StyledTableCell>
      )}
      <StyledTableCell>{order.items.reduce((total: number, item: any) => total + item.quantity, 0)}</StyledTableCell>
    </TableRow>
  );
};

export default OrderTableRow;
