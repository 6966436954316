import { styled, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import ExpandableText from 'components/ExpandableText';
import { Routes } from 'lib/enums';
import { checkCountryCurrency } from 'lib/helpers';
import { CateringOrder } from 'lib/interfaces';
import { sum } from 'lodash-es';
import { Link } from 'react-router-dom';
import { PaymentStatus } from '../../common';
import { formatDateTime, formatDeliveryHour } from '../../helpers';

interface CateringOrderListRowProps {
  order: CateringOrder;
  isLoading: boolean;
}

export default function CateringOrderListRow({ order, isLoading }: CateringOrderListRowProps) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
      fontWeight: 500,
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily,
      border: 'none',
      verticalAlign: 'sub'
    }
  }));

  return (
    <TableRow
      sx={{
        opacity: isLoading ? 0.6 : 1
      }}
    >
      <StyledTableCell>
        <Link to={Routes.cateringOrder.replace(':id', order.id)}>
          <Typography
            sx={{
              color: caloTheme.palette.primary.main,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            CI-{order.referenceId}
          </Typography>
        </Link>
      </StyledTableCell>
      <StyledTableCell>{order.user.fullName}</StyledTableCell>
      <StyledTableCell>{sum(order.orders.flatMap(({ items }) => items.map(({ count }) => count)))}</StyledTableCell>
      <StyledTableCell>
        {order.cost} {checkCountryCurrency(order.deliveryAddress.country)}
      </StyledTableCell>
      <StyledTableCell>
        {order.deliveryAddress.city}, {order.deliveryAddress.country}
      </StyledTableCell>
      <StyledTableCell style={{ minWidth: '150px' }}>{formatDateTime(order.createdAt)}</StyledTableCell>
      <StyledTableCell>{order.date}</StyledTableCell>
      <StyledTableCell>{formatDeliveryHour(order.deliveryHour)}</StyledTableCell>
      <StyledTableCell>
        <PaymentStatus status={order.status} />
      </StyledTableCell>
      <StyledTableCell sx={{ maxWidth: '240px', minWidth: '240px' }}>
        {order.notes?.length ? (
          <ExpandableText
            text={(order.notes ?? []).reduce<string>((acc, { name, note }, i) => {
              const text = `<b>${name}</b>: ${note}`;
              return i ? `${acc}<br><br>${text}` : text;
            }, '')}
            maxHeight={250}
          />
        ) : (
          <Typography>-</Typography>
        )}
      </StyledTableCell>
    </TableRow>
  );
}
