import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, CardMedia, IconButton, Stack, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { getIngredientsAllergens } from 'lib/helpers';
import { getImageUrl, ImageVariant } from 'lib/helpers/getImageUrl';
import { FoodComponent } from 'lib/interfaces';
import { MacrosCard } from '../../../Shared';
import IngredientList from '../../../Shared/IngredientList';

interface MealDetailsBoxProps {
  component: FoodComponent;
  closeHandler: () => void;
}

const MealDetailsBox = ({ component, closeHandler }: MealDetailsBoxProps) => {
  const allAllergens = getIngredientsAllergens(component.ingredients);

  const displayImage = getImageUrl(component.imageUrl, ImageVariant.square1x);

  return (
    <>
      <Stack
        sx={{
          position: 'fixed',
          top: '42%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
          backgroundColor: 'white',
          minWidth: '320px',
          width: '320px',
          borderRadius: '8px',
          paddingX: '16px',
          paddingY: '12px',
          boxShadow: '0px 8px 16px 0px #0000001A',
          color: '#3A3A3A',
          cursor: 'default'
        }}
      >
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Stack sx={{ flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
            <CardMedia
              component="img"
              image={displayImage}
              alt="placeholder"
              sx={{ width: 38, maxHeight: 38, height: 38, objectFit: 'cover', borderRadius: '8px' }}
              onError={(e: any) => {
                e.target.src = noImagePlaceholder;
              }}
            />
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{component.name.en}</Typography>
          </Stack>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              closeHandler();
            }}
            sx={{
              ':hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <CloseIcon sx={{ color: caloTheme.palette.secondaryBlue700 }} />
          </IconButton>
        </Stack>
        <MacrosCard macros={component.macros} sx={{ marginTop: '18px', backgroundColor: 'transparent', color: '#3A3A3A' }} />
        <Stack sx={{ alignSelf: 'flex-start' }}>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, color: '', alignSelf: 'flex-start', marginTop: '4px', marginBottom: '8px' }}
          >
            Ingredients
          </Typography>
          <IngredientList ingredients={component.ingredients} />
          {allAllergens.length > 0 && (
            <>
              <Stack sx={{ flexDirection: 'row', gap: '4px', marginTop: '8px', marginBottom: '4px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Allergen alert</Typography>
                <WarningAmberRoundedIcon sx={{ color: caloTheme.palette.red500 }} fontSize="small" />
              </Stack>
              <Stack sx={{ alignItems: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', gap: '4px' }}>
                <Typography sx={{ fontSize: '14px', textAlign: 'left' }}>
                  {component.name.en} contains{' '}
                  <Box component="span" sx={{ color: 'red' }}>
                    {allAllergens.join(', ')}
                  </Box>
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Box
        onClick={(event) => {
          event.stopPropagation();
          closeHandler();
        }}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          zIndex: 99,
          cursor: 'default'
        }}
      />
    </>
  );
};

export default MealDetailsBox;
