import { CreateFoodComponentReq, SyncFoodComponentReq } from '@calo/dashboard-types';
import { UpdateFoodComponentReq } from 'lib/calo-dashboard-types';
import client from 'lib/client';
import { PrototypeAction } from 'lib/enums';
import { ToastSuccessMsgs, getPrototypeActionSuccessMessage } from 'lib/helpers';
import { SyncFoodComponentMPErrors, UpdatePrototypeActionsReq } from 'lib/interfaces';
import mutation from './mutation';

export const createFoodComponent = async (fc: CreateFoodComponentReq) => {
  const { data, status } = await client.post('/food-components', fc);
  ToastSuccessMsgs({ status, action: 'Component created successfully' });
  mutation(['food-components', data.id], data);
  return data;
};

export const createPrototypeComponent = async (fc: CreateFoodComponentReq) => {
  const { data, status } = await client.post('/food-components/prototype', fc);
  ToastSuccessMsgs({ status, action: 'Component created successfully' });
  mutation(['food-components', data.id], data);
  return data;
};

export const addPrototypeComponentAction = async ({ id, ...rest }: UpdatePrototypeActionsReq & { id: string }) => {
  const { data, status } = await client.put(`/food-components/prototype/${id}/add-action`, rest);
  const message = getPrototypeActionSuccessMessage(rest.action, 'Component');
  ToastSuccessMsgs({ status, action: message });
  return data;
};

export const approveStage1PrototypeComponent = async (id: string) => {
  const { data, status } = await client.put(`/food-components/prototype/${id}/approve-stage-1`, {
    action: PrototypeAction.submitForOpsApproval
  });
  const message = getPrototypeActionSuccessMessage(PrototypeAction.submitForOpsApproval, 'Component');
  ToastSuccessMsgs({ status, action: message });
  return data;
};

export const approveStage2PrototypeComponent = async (id: string) => {
  const { data, status } = await client.put(`/food-components/prototype/${id}/approve-stage-2`, {
    action: PrototypeAction.approve
  });
  const message = getPrototypeActionSuccessMessage(PrototypeAction.approve, 'Component');
  ToastSuccessMsgs({ status, action: message });
  return data;
};

export const deletePrototypeComponent = async (id: string) => {
  const { status } = await client.delete(`/food-components/prototype/${id}`);
  ToastSuccessMsgs({ status, action: 'Component deleted successfully' });
};

export const updateFoodComponent = async (payload: UpdateFoodComponentReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/food-components/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Component updated successfully' });
  mutation(['food-components', id], data);
};

export const updatePrototypeFoodComponent = async (payload: UpdateFoodComponentReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/food-components/prototype/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Component updated successfully' });
  mutation(['food-components', id], data);
};

export const exportFoodComponent = async (filters: any) => {
  const { status } = await client.get('food-components/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
};

export const syncFoodComponent = async (payload: SyncFoodComponentReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data } = await client.put(`/food-components/${id}/sync`, rest);
  return data as { data: SyncFoodComponentMPErrors[]; isSuccess: boolean };
};

export const deleteFoodComponent = async (payload: { id: string }) => {
  const { id } = payload;
  const { data, status } = await client.delete(`/food-components/${id}`);
  ToastSuccessMsgs({ status, action: 'Component deleted successfully' });
  return data;
};

export const validateComponentFood = async (payload: UpdateFoodComponentReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data } = await client.put(`/food-components/${id}/validate`, rest);
  return data;
};
