import { useEffect, useState } from 'react';

import { FormikBag, FormikProps, withFormik } from 'formik';

import { AddToWallet, PaymentSource, WalletAction } from '@calo/dashboard-types';
import { Currency, PaymentMethod, PaymentProviderType, PlanType, UserAction, WalletReason } from '@calo/types';
import { Box, Button, FormControlLabel, MenuItem, Switch, SwitchProps, TextField, styled } from '@mui/material';

import { checkEligibilityForPoints } from 'actions/loyalty';
import { caloTheme } from 'assets/images/theme/calo';
import { TextArea } from 'components';
import { walletSubReasonsHelper } from 'lib/helpers/walletReasonHelpers';

interface NewWalletFormProps {
  onSubmit: (values: AddToWallet) => any;
  paymentMethod: PaymentMethod;
  userCurrency: Currency;
  userId: string;
}

const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
);

const NewWalletForm = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  userCurrency,
  userId,
  errors
}: NewWalletFormProps & FormikProps<AddToWallet>) => {
  const refundReasons = [
    WalletReason.INVAILID_ENTRY,
    WalletReason.DOUBLE_ENTRY,
    WalletReason.REFUNDED_MANUALLY,
    WalletReason.FIXING_BALANCE,
    WalletReason.CREDIT_TRANSFER,
    WalletReason.REMOVING_GIFTS,
    WalletReason.REMOVING_DISCOUNT_CODE_CREDITS,
    WalletReason.OTHER
  ];

  const addReasons = [
    WalletReason.CALO_TEAM_PERKS,
    WalletReason.CASH_OR_BENEFIT_PAYMENT,
    WalletReason.COMPENSATION,
    WalletReason.MANUAL_DISCOUNT,
    WalletReason.CX_GIFT,
    WalletReason.MARKETING_GIFT_AND_EXPERIMENTS,
    WalletReason.CALO_BUSINESS,
    WalletReason.TRANSFERRED_CREDITS,
    WalletReason.CUSTOMERS_INFLUENCERS,
    WalletReason.CUSTOMERS_CALO_EXPERIMENTS,
    WalletReason.COMPLEMENTARY_CARD,
    WalletReason.OTHER
  ];

  const onlinePaymentActions = Object.values(UserAction);

  const subReasonOptions = walletSubReasonsHelper.getOptions();

  useEffect(() => {
    setFieldValue('currency', userCurrency);
    setFieldValue('userId', userId);
  }, []);

  const [subReason, setSubReason] = useState<string | undefined>(undefined);

  const handlePreSubmit = async (data: AddToWallet & { subReason?: string }) => {
    const subReasonList = walletSubReasonsHelper.getObject();

    if (data.subReason) {
      //@ts-ignore
      const subReasonLabel = subReasonList[data.reason] && subReasonList[data.reason][data.subReason];
      data.notes = data.notes ? `${subReasonLabel} - ${data.notes}` : subReasonLabel;
      setFieldValue('notes', data.notes);
    }

    handleSubmit();
  };
  const emptyAmountError = 'Enter an amount in order to add loyalty points';
  const defaultLoyaltyLabel = 'Add loyalty points?';

  const [loyaltyPointsLable, setLoyaltyPointsLable] = useState(defaultLoyaltyLabel);
  const [eligibleForLoyaltyPoints, setEligible] = useState(false);
  const [points, setPoints] = useState(0);
  const [amountError, setAmountError] = useState(false);

  useEffect(() => {
    const eligible = async () => {
      const data = await checkEligibilityForPoints(values.userId, values.amount);
      if (data.eligible) {
        setEligible(true);
        setPoints(data.points);
      } else {
        setEligible(false);
        setPoints(0);
        setLoyaltyPointsLable(data.message);
      }
    };
    const timer = setTimeout(() => {
      if (values.hasLoyaltyPoints === true && values.amount && values.amount > 0) {
        setLoyaltyPointsLable('Calculating...');
        setAmountError(false);
        eligible();
      } else if (values.hasLoyaltyPoints === true && (!values.amount || values.amount <= 0)) {
        setLoyaltyPointsLable(emptyAmountError);
        setEligible(false);
        setAmountError(true);
        setPoints(0);
      } else {
        setLoyaltyPointsLable(defaultLoyaltyLabel);
        setEligible(false);
        setAmountError(false);
        setPoints(0);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [values.hasLoyaltyPoints, values.amount]);

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
        width: '401px',
        mx: 'auto',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          width: '100%',
          mx: 1
        }
      }}
    >
      <TextField
        select
        label="Action"
        name="action"
        sx={{
          width: '100%',
          mr: 2,
          mb: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        value={values.action}
        id="exact-subscription-newWallet-action"
        onChange={(value: any) => {
          setFieldValue('action', value.target.value);
          value !== WalletAction.ADD && setFieldValue('hasLoyaltyPoints', false);
        }}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
      >
        {[
          { value: WalletAction.ADD, label: 'ADD' },
          { value: WalletAction.REFUND, label: 'REFUND' }
        ].map((p) => (
          <MenuItem key={p.value} value={p.value}>
            {p.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Source"
        name="source"
        value={values.source}
        sx={{
          width: '100%',
          mr: 2,
          mb: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        id="exact-subscription-newWallet-source"
        onChange={(value: any) => {
          setFieldValue('source', value.target.value);
          ![PaymentSource.offline, PaymentSource.online].includes(value) && setFieldValue('hasLoyaltyPoints', false);

          if (value.target.value === PaymentSource.online) {
            setFieldValue('paymentProvider', PaymentProviderType.TAP);
            setFieldValue('paymentMethod', PaymentMethod.cc);
          } else {
            setFieldValue('paymentProvider', undefined);
            setFieldValue('paymentMethod', PaymentMethod.cash);
          }
        }}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
      >
        {(values.action === WalletAction.ADD
          ? [
              { value: PaymentSource.online, label: 'Payment Link' },
              { value: PaymentSource.offline, label: 'Cash' },
              { value: PaymentSource.credits, label: 'Credits' }
            ]
          : [{ value: PaymentSource.credits, label: 'Credits' }]
        ).map((p) => (
          <MenuItem key={p.value} value={p.value}>
            {p.label}
          </MenuItem>
        ))}
      </TextField>

      {values.action === WalletAction.ADD && values.source === PaymentSource.online ? (
        <TextField
          select
          label="Payment Provider"
          name="paymentProvider"
          value={values.paymentProvider}
          sx={{
            width: '100%',
            mr: 2,
            mb: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              marginTop: 2,
              width: '75%',
              mx: 1
            }
          }}
          id="exact-subscription-newWallet-source"
          onChange={(value: any) => {
            setFieldValue('paymentProvider', value.target.value);
          }}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          {Object.values(PaymentProviderType)
            .map((type) => ({
              label: type,
              value: type
            }))
            .map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {p.label}
              </MenuItem>
            ))}
        </TextField>
      ) : null}

      <TextField
        type="number"
        name="amount"
        label="Amount"
        sx={{
          width: '401px',
          mb: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        onBlur={handleBlur}
        error={Boolean(errors.amount)}
        helperText={errors.amount}
        value={values.amount}
        onChange={handleChange}
        placeholder="Enter Amount"
        id="exact-subscription-amount"
        InputProps={{ inputProps: { style: { borderRadius: 8 }, min: 0 }, style: { borderRadius: 8, width: '100%' } }}
      />

      {[PaymentSource.online, PaymentSource.offline].includes(values.source) && values.action === WalletAction.ADD && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {!eligibleForLoyaltyPoints && !amountError && <label> {loyaltyPointsLable} </label>}
          {!eligibleForLoyaltyPoints && amountError && <label style={{ color: 'red' }}> {loyaltyPointsLable} </label>}
          {eligibleForLoyaltyPoints && (
            <label>
              {' '}
              <span style={{ color: 'green' }}>{points} loyalty points</span> will be added{' '}
            </label>
          )}
          <FormControlLabel
            control={<IOSSwitch sx={{ mb: 2, mr: 1.5 }} defaultChecked={values.hasLoyaltyPoints} />}
            label=""
            labelPlacement="start"
            value={values.hasLoyaltyPoints}
            name="hasLoyaltyPoints"
            onChange={handleChange}
            id="exact-subscription-newWallet-hasLoyaltyPoints"
          />
        </Box>
      )}

      {/* <TextField
        select
        label="Payment Method"
        name="paymentMethod"
        value={values.paymentMethod}
        sx={{
          width: '100%', mr: 2, mb: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        id="exact-subscription-paymentMethod"
        onChange={(value: any) => setFieldValue('paymentMethod', value.target.value)}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
      >
        {[
          { value: PaymentMethod.cash, label: 'Cash' },
        ].map(p => (
          <MenuItem key={p.value} value={p.value}>{(p.label)}</MenuItem>
        ))}
      </TextField> */}

      {/* <TextField
        type='text'
        name='currency'
        disabled
        label="Currency"
        sx={{
          width: '401px', mb: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        onBlur={handleBlur}
        value={userCurrency}
        onChange={handleChange}
        id="exact-subscription-currency"
        InputProps={{ inputProps: { style: { borderRadius: 8 }, min: 0 }, style: { borderRadius: 8, width: '100%' }, }}
      /> */}

      <TextField
        select
        label="Reason"
        name="reason"
        value={values.reason}
        sx={{
          width: '100%',
          mr: 2,
          mb: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        id="exact-subscription-reason"
        onChange={(data: any) => {
          setFieldValue('reason', data.target.value);
          if ((data.target.value as unknown as UserAction) === UserAction.CREATE_SUBSCRIPTION) {
            setFieldValue('planType', PlanType.full);
          } else {
            setFieldValue('planType', undefined);
          }
        }}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
      >
        {(values.action === WalletAction.ADD
          ? [PaymentSource.online, PaymentSource.offline].includes(values.source)
            ? onlinePaymentActions
            : addReasons
          : refundReasons
        ).map((p) => (
          <MenuItem key={p} value={p}>
            {p.replaceAll('_', ' ')}
          </MenuItem>
        ))}
      </TextField>

      {[PaymentSource.online, PaymentSource.offline].includes(values.source) &&
      (values.reason as unknown as UserAction) === UserAction.CREATE_SUBSCRIPTION ? (
        <TextField
          select
          label="Plan Type"
          name="planType"
          //@ts-ignore
          value={values.planType}
          sx={{
            width: '100%',
            mr: 2,
            mb: 2,
            textTransform: 'capitalize',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              marginTop: 2,
              width: '75%',
              mx: 1
            }
          }}
          id="exact-subscription-newWallet-source"
          onChange={(value: any) => {
            setFieldValue('planType', value.target.value);
          }}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          {Object.values(PlanType)
            .map((type) => ({
              label: type.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
              value: type
            }))
            .map((p) => (
              <MenuItem sx={{ textTransform: 'capitalize' }} key={p.value} value={p.value}>
                {p.label}
              </MenuItem>
            ))}
        </TextField>
      ) : null}

      <TextField
        select
        label="Sub Reason"
        name="subReason"
        value={subReason}
        sx={{
          width: '100%',
          mr: 2,
          mb: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        id="exact-subscription-reason"
        onChange={(data: any) => setSubReason(data.target.value)}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
      >
        {
          // @ts-ignore
          (subReasonOptions[values.reason] || [{ value: '', label: 'Select' }]).map((p) => (
            <MenuItem key={p.value} value={p.value}>
              {p.label}
            </MenuItem>
          ))
        }
      </TextField>

      <TextArea
        label="Notes"
        name="notes"
        value={values.notes}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '70%',
            mx: 1
          }
        }}
      />

      <Button
        variant="contained"
        aria-label="Add Credits"
        disabled={
          Object.values(errors).length > 0 ||
          !values.reason ||
          !values.amount ||
          !values.source ||
          (values.source === PaymentSource.online && !values.paymentProvider) ||
          ([PaymentSource.online, PaymentSource.offline] &&
            (values.reason as unknown as UserAction) === UserAction.CREATE_SUBSCRIPTION &&
            !values.planType)
        }
        sx={{
          width: 'auto',
          height: '45px',
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px',
          padding: '14px 20px 14px 20px',
          backgroundColor: caloTheme.palette.primary500,
          borderColor: caloTheme.palette.primary500,
          color: 'white',
          '&:hover': {
            color: 'white',
            backgroundColor: caloTheme.palette.primary600,
            borderColor: caloTheme.palette.primary600
          },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        onClick={() =>
          handlePreSubmit({
            ...values,
            subReason
          })
        }
      >
        Add Credits
      </Button>
    </Box>
  );
};

const valuesToMap = {
  amount: 10,
  action: WalletAction.ADD,
  source: PaymentSource.credits,
  paymentMethod: PaymentMethod.cash,
  currency: Currency.BHD,
  userId: ''
};

export default withFormik({
  mapPropsToValues: () => ({
    ...valuesToMap,
    ...(valuesToMap.source === PaymentSource.offline && {
      hasLoyaltyPoints: false
    })
  }),
  validate: (values: AddToWallet) => {
    const errors: any = {};
    if (
      [Currency.BHD, Currency.KWD, Currency.OMR, Currency.GBP].includes(values.currency) &&
      (values.amount <= 0 || values.amount > 9999)
    ) {
      errors.amount = `This is an invalid amount for ${values.currency} currency. Please enter an amount between 1 and 9999`;
    } else if (values.amount <= 0 || values.amount > 99999) {
      errors.amount = `This is an invalid amount for ${values.currency} currency. Please enter an amount between 1 and 99999`;
    }
    return errors;
  },

  // @ts-ignore
  handleSubmit: async (values, { setSubmitting, props, resetForm }: FormikBag<NewWalletFormProps, AddToWallet>) => {
    try {
      console.log({ values });
      await props.onSubmit(values as AddToWallet);
      resetForm();
    } catch {
      setSubmitting(false);
    }
  }
})(NewWalletForm as any);
