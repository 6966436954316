import { format, parseISO } from 'date-fns/fp';
import { capitalize, omit } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { FoodComponentStation, FoodStatsFilters } from '@calo/dashboard-types';
import { Country, DietType, Kitchen } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { toggleUISettings } from 'actions';
import { Select, Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { DeliveryTime } from 'lib/enums';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';

interface SettingsProps {
  onFilter: (values: FoodStatsFilters) => any;
  filters: FoodStatsFilters;
  customSizes: string[];
  selectedCustomSize: string | undefined;
  setSelectedCustomSize: (value: string) => void;
}

const Settings = ({ filters, onFilter, customSizes, selectedCustomSize, setSelectedCustomSize }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country,
              kitchen: data.target.value
                ? (userKitchens.find((k) => k.includes(data.target.value)) as Kitchen)
                : ('' as Kitchen) || undefined
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country!)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0 || !filters.country}
        />
        <Select
          label="Delivery Time"
          value={filters.time}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['time']),
              ...(v.value && {
                time: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            {
              value: DeliveryTime.earlyMorning,
              label: 'Early Morning'
            },
            {
              value: DeliveryTime.morning,
              label: 'Morning'
            },
            {
              value: DeliveryTime.evening,
              label: 'Evening'
            }
          ]}
        />
        <div className="field">
          <label className="label">Date range</label>
          <div className="control is-clearfix">
            <DateRangePicker
              clearIcon={null}
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  day: {
                    gte: format('yyyy-MM-dd')(dates[0]),
                    lte: format('yyyy-MM-dd')(dates[1])
                  }
                })
              }
              selectRange
              returnValue="range"
              value={[parseISO(filters.day.gte), parseISO(filters.day.lte)]}
            />
          </div>
        </div>
        <Select
          data-test="stationSelect"
          label="Section"
          value={filters.station}
          options={[
            {
              value: undefined,
              label: 'Any'
            },
            ...Object.keys(FoodComponentStation).map((value) => ({
              value,
              label: capitalize(value)
            }))
          ]}
          onChange={(station: any) => {
            onFilter({ ...filters, station: station.value });
          }}
        />
        <Select
          data-test="mealSize"
          label="Custom Meal Size"
          value={selectedCustomSize}
          isDisabled={!customSizes}
          options={[
            {
              value: undefined,
              label: 'Any'
            },
            ...customSizes.map((value) => ({
              value,
              label: value
            }))
          ]}
          onChange={(mealSize: any) => {
            setSelectedCustomSize(mealSize.value);
          }}
        />
        <Select
          label="User Diet Type"
          value={filters.dietType}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['dietType']),
              ...(v.value && {
                dietType: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(DietType).map((ft) => ({
              value: ft,
              label: capitalize(ft)
            }))
          ]}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
