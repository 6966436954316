import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import { Icon as Iconify } from '@iconify/react';
import { caloTheme } from 'assets/images/theme/calo';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import Icon from 'components/Icon';
import { Routes } from 'lib/enums';
import { getFoodAggregatedRatingsForPeriod, types } from 'lib/helpers';
import { getImageUrl, ImageVariant } from 'lib/helpers/getImageUrl';
import { useUserRoles } from 'lib/hooks';
import { Food } from 'lib/interfaces';

interface FoodRowProps {
  food: Food;
  isDraft: boolean;
  index: number;
}

const FoodRow = ({ food, isDraft, index }: FoodRowProps) => {
  const roles = useUserRoles();
  const aggregatedRatings = getFoodAggregatedRatingsForPeriod(food, 90);

  const displayImage = getImageUrl(food.imageUrl, ImageVariant.square1x);

  return (
    <tr>
      <td>
        <div className="flex">
          <span className="mt-2 w-14 h-14">
            <img
              className="object-cover h-full rounded-s-md"
              width={120}
              alt={food.name.en}
              onError={(e: any) => (e.target.src = noImagePlaceholder)}
              src={displayImage}
            />
          </span>
          <span className="ml-2 mt-4 flex">
            {roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) ? (
              <Link data-test={`foodRow#${index}`} to={Routes.foodSlug.replace(':slug', food.slug)}>
                {food.size ? `${food.name.en}(${food.size})` : food.name.en}
              </Link>
            ) : (
              `${food.name.en}(${food.size})`
            )}
          </span>
        </div>
      </td>
      {!isDraft && (
        <>
          <td>
            <span className="flex">
              <span className="flex mr-2">
                <Icon name="star" size={4} className="mt-2" />
                {!!food.totalRating && !!food.numberOfRatings ? (
                  <p className="text-black px-1 mt-1">
                    {(food.totalRating / food.numberOfRatings).toFixed(2)} ({food.numberOfRatings})
                  </p>
                ) : (
                  <p className="text-black px-1 mt-1">
                    {0} ({0})
                  </p>
                )}
              </span>
              <span className="flex">
                <Icon name="star" size={4} className="mt-2" />
                {aggregatedRatings ? (
                  <p className="text-black px-1 mt-1">
                    {aggregatedRatings.average} ({aggregatedRatings.count})
                  </p>
                ) : (
                  <p className="text-black px-1 mt-1">
                    {0} ({0})
                  </p>
                )}
              </span>
            </span>
          </td>
          <td>
            <span className="flex ">
              <span className="flex mr-2">
                <Iconify icon="mdi:heart" style={{ fontSize: 16, color: caloTheme.palette.secondaryRed500 }} className="mt-2" />
                {''}
                <p className="text-black px-1 mt-1">{food?.totalNumberOfFavorites ?? 0}</p>
              </span>
            </span>
          </td>
          <td>
            <span className="flex">
              <span className="flex mr-2">
                <Icon name="blockIcon" size={4} className="mt-2" />
                {''}
                <p className="text-black px-1 mt-1">{food?.totalNumberOfBlocks ?? 0}</p>
              </span>
            </span>
          </td>
        </>
      )}

      <td>
        <p>{food.description?.en.length > 25 ? `${food.description?.en.slice(0, 25)}...` : food.description?.en}</p>
      </td>
      <td>
        <p>{food.country}</p>
      </td>
      <td>
        <p>{food.kitchen}</p>
      </td>
      <td>
        <div className="tags capitalize">{food.type?.map((type) => types(type))}</div>
      </td>
      <td>
        <div className="tags capitalize">{food.tags?.map((type) => <FoodDietTypeTags key={type} tag={type} />)}</div>
      </td>
    </tr>
  );
};

export default FoodRow;
