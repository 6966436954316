import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { CateringOrder } from 'lib/interfaces';
import { InformationCard } from '../../common';
import { formatDeliveryHour } from '../../helpers';
import ContactInfoCard from '../ContactInfoCard';

export interface ContactInfoProps {
  cateringOrder: CateringOrder;
}

export default function ContactInfo({ cateringOrder }: ContactInfoProps) {
  const deliveryAddress = cateringOrder.deliveryAddress;

  return (
    <InformationCard heading={'Contact Info'}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <ContactInfoCard name={'Business Name'} value={cateringOrder.user.companyName} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContactInfoCard name={'Customer Name'} value={cateringOrder.user.fullName} />
        </Grid>
        <Grid item xs={3}>
          <ContactInfoCard name={'Phone Number'} value={cateringOrder.user.phoneNumber} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContactInfoCard name={'Email'} value={cateringOrder.user.email} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContactInfoCard
            name={'Address'}
            value={[
              deliveryAddress.country,
              deliveryAddress.city,
              deliveryAddress.postalCode,
              deliveryAddress.street,
              deliveryAddress.name
            ]
              .filter(Boolean)
              .join(', ')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContactInfoCard name={'Order Date'} value={format(new Date(cateringOrder.createdAt), 'dd/MM/yyyy, HH:mm')} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContactInfoCard name={'Delivery Date'} value={cateringOrder.date} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContactInfoCard name={'Delivery Time'} value={formatDeliveryHour(cateringOrder.deliveryHour)} />
        </Grid>
      </Grid>
    </InformationCard>
  );
}
