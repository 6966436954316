import { Brand, RetailBranch } from '@calo/types';
import { getListWithParams } from 'actions/index';
import { simpleMutation } from 'actions/mutation';
import { updateRetailOrderItems, updateRetailOrderStatus } from 'actions/retail';
import { differenceInMinutes, format } from 'date-fns';
import { Routes } from 'lib/enums';
import { useUserData } from 'lib/hooks';
import { uniqBy } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';
import { baristaEmailMapping, notificationActions } from 'views/Retail/constant';
import notificationSound from '../../../../assets/audio/notification-sound.mp3';
import { PickUpStatus, RetailFilter } from '../../types';
import { filterOrdersByStatus, getFilteredItems, getOrdersForCurrentTab, handleQuerySuccess } from './helpers';
import { CarHopDetails } from './types';
const ordersPerPage = 10;

// Ensure audio is initialized before use
const audio = new Audio(notificationSound);
audio.loop = true;

const initialCarHopDetails = {
  orderId: '',
  name: '',
  phoneNumber: '',
  vehiclePlateNumber: '',
  pickUpAt: '',
  pickUpId: ''
};

interface UseRetailOrderProps {
  history: {
    push: (path: string | { pathname: string; search: string }) => void;
  };
  location: any;
}

export const useRetailOrder = ({ history, location }: UseRetailOrderProps) => {
  const searchParams = new URLSearchParams(location.search);
  const user = useUserData();

  // Declare state variables at the top
  const [filters, setFilters] = useState<RetailFilter>({
    brand: Brand.CALO,
    branch: baristaEmailMapping[user.email]?.[0] ?? RetailBranch.SEEF,
    ...JSON.parse(searchParams.get('filters') || '{}')
  });

  const [notification, setNotification] = useState<{ open: boolean; title: string }>({
    open: false,
    title: ''
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [soundTest, setSoundTest] = useState(false);
  const [needReEnableAudio, setNeedReEnableAudio] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [loadingItemIds, setLoadingItemIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<'createdAt' | 'pickUpAt'>('createdAt');
  const [dateError, setDateError] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<string>(format(new Date(), 'hh:mm a'));
  const [carHopDetails, setCarHopDetails] = useState<CarHopDetails>(initialCarHopDetails);
  const [currentBranch, setCurrentBranch] = useState(filters.branch);
  const [paginationToken, setPaginationToken] = useState<string | null>(null);
  const [playNotificationSound, setPlayNotificationSound] = useState(true);
  const [OverDueAndSoonOrders, setOverDueAndSoonOrders] = useState<any[]>([]);
  const [currentOverdueIndex, setCurrentOverdueIndex] = useState<number>(0);
  const [showedAllNotification, setShowedAllNotification] = useState(false);

  const [orderList, setOrderList] = useState<any[]>([]);

  const orders = useMemo(
    () =>
      getOrdersForCurrentTab({
        selectedTab,
        orderList,
        filters,
        selectedDate,
        endDate,
        searchQuery
      }),
    [selectedTab, orderList, filters, selectedDate, endDate, searchQuery]
  );

  useEffect(() => {
    if (selectedTab === 0) {
      const list = orders.filter((order) => {
        const pickUpTime = new Date(order.pickUp.pickUpAt);
        const currentTime = new Date();
        const difference = differenceInMinutes(pickUpTime, currentTime);
        return difference < 10;
      });
      if (list.length > 0 && list.length !== OverDueAndSoonOrders.length) {
        setOverDueAndSoonOrders(list);
        setCurrentOverdueIndex(0);
        setShowedAllNotification(false);
        setPlayNotificationSound(true);
      }
    }
  }, [orders, selectedTab]);

  useEffect(() => {
    if (showedAllNotification) {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [showedAllNotification]);

  const handleOverdueOrderClose = () => {
    audio.pause();

    audio.currentTime = 0;
    if (currentOverdueIndex < OverDueAndSoonOrders.length - 1) {
      setCurrentOverdueIndex((prevIndex) => prevIndex + 1);
    } else {
      setShowedAllNotification(true);
    }
  };

  useEffect(() => {
    const path = generatePath(Routes.retailOrderList, { brand: filters.brand, branch: filters.branch });
    history.push(path);
    searchParams.set('filters', JSON.stringify(filters));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  }, [filters.brand, filters.branch, history, location.pathname]);

  const handleUserInteraction = () => {
    setIsUserInteracted(true);
    setSoundTest(true);
    setNeedReEnableAudio(false);

    audio
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error('Error playing sound:', error);
      });
  };

  const startPlayingSound = () => {
    if (!isPlaying) {
      audio
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Error playing sound:', error);
          setNotification((prev) => ({ ...prev, open: true }));
        });
    }
  };

  const stopPlayingSound = () => {
    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    }
  };
  const resetBranchData = (deleteOrderList = false) => {
    if (deleteOrderList) {
      setOrderList([]);
    }
    setCurrentBranch(filters.branch);
    setCurrentOverdueIndex(0);
    setShowedAllNotification(false);
    setOverDueAndSoonOrders([]);
    setCurrentPage(0);
    setPaginationToken(null);
    refetch();
    setLastUpdated(format(new Date(), 'hh:mm a'));
  };

  const handleShowNotificationCard = () => {
    startPlayingSound();
    setNotification({ title: 'New Order', open: true });
  };

  const handleServiceWorkerMessage = (event: any) => {
    const { data } = event;
    if (data.action === notificationActions.CUSTOMER_ARRIVED) {
      const [orderId, branch] = data.body.split('-');
      const isRestrictedUser = Boolean(baristaEmailMapping[user.email]);
      const isValidBranch = baristaEmailMapping[user.email]?.includes(branch);

      if (isRestrictedUser && !isValidBranch) return;
      showCarHopOrderPopup(orderId);
    } else if (data.action === notificationActions.NEW_RETAIL_ORDER) {
      startPlayingSound();
      resetBranchData();
      setNotification({ title: data.title || '', open: true });
    }
  };

  const showCarHopOrderPopup = (orderId: string) => {
    const order = orderList.find((order) => order.orderId === orderId);
    if (!order) return;

    const { name, phoneNumber } = order.user;
    const { vehiclePlateNumber, pickUpAt, id } = order.pickUp;
    const carHopOrder = { orderId, name, phoneNumber, vehiclePlateNumber, pickUpAt, pickUpId: id };
    startPlayingSound();
    setCarHopDetails(carHopOrder);
  };

  const resetCarHopDetails = () => {
    setCarHopDetails(initialCarHopDetails);
    stopPlayingSound();
  };

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
    script1.defer = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      window.OneSignalInitialized = window.OneSignalInitialized || false;
      OneSignalDeferred.push(async function(OneSignal) {
        const filters = ${JSON.stringify(filters)};
        if (!window.OneSignalInitialized) {
          await OneSignal.init({
            appId: '${process.env.REACT_APP_ONE_SIGNAL_APP_ID}'
          });
          window.OneSignalInitialized = true;
        }
        OneSignal.User.addTags({ ['retail-staff-' + filters.branch]: 'true' });
      });
    `;
    document.body.appendChild(script2);

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, [filters.branch, handleServiceWorkerMessage]);

  const [fullOrderListLength, setFullOrderListLength] = useState<number | null>(null);

  const { isLoading, refetch, isFetching, error } = useQuery<any, Error, { pickUps: any[]; token?: string }>(
    ['/retail/orders', { branch: filters.branch, token: paginationToken }],
    getListWithParams,
    {
      suspense: false,
      initialData: { pickUps: [], token: null },
      onSuccess: (newData) =>
        handleQuerySuccess({
          newData,
          paginationToken: paginationToken ?? '',
          fullOrderListLength: fullOrderListLength ?? 0,
          orderList,
          filters,
          currentBranch,
          setPaginationToken,
          setFullOrderListLength,
          handleShowNotificationCard,
          setOrderList
        })
    }
  );
  const isUnauthorized = (error as any)?.response?.status === 401;

  const handleChangeStatus = async (pickUpId: string, status: PickUpStatus, reason?: string) => {
    audio.pause();
    if (orderList && orderList.length > 0 && !loadingItemIds.includes(pickUpId)) {
      const oldList = orderList?.filter((order) => order.pickUp.id !== pickUpId) ?? [];
      const currentItem = orderList?.find((order) => order.pickUp.id === pickUpId);
      try {
        setLoadingItemIds((prev) => [...prev, pickUpId]);
        const newList = [
          ...oldList,
          { ...currentItem, pickUp: { ...currentItem.pickUp, status, cancellationReason: reason } }
        ].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        simpleMutation(['/retail/orders', { branch: filters.branch }], newList);
        setOrderList(uniqBy(newList, 'id'));
        await updateRetailOrderStatus(pickUpId, status, reason);
      } catch (error) {
        console.log(error);
        const newList = [...oldList, currentItem].sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        simpleMutation(['/retail/orders', { branch: filters.branch }], newList);
        setOrderList(uniqBy(newList, 'id'));
      } finally {
        setLoadingItemIds((prev) => prev.filter((id) => id !== pickUpId));
      }
    }
  };

  const handleItemUpdate = async ({ orderId, items, orderNo }: { orderId: string; items: any; orderNo: string }) => {
    try {
      const updatedOrder = await updateRetailOrderItems({ orderId, items, orderNo });
      const newOrderList = orderList.map((order) => (order.id === orderId ? { ...order, items: updatedOrder.items } : order));
      setOrderList(newOrderList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSoundTestConfirmation = () => {
    stopPlayingSound();
    setSoundTest(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentPage(0);
    setSelectedTab(newValue);
  };

  const handleSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        setSearchQuery(value);
      }, 300),
    []
  );
  const showNotification = useMemo(() => {
    return (
      OverDueAndSoonOrders.length > 0 &&
      currentOverdueIndex < OverDueAndSoonOrders.length &&
      !showedAllNotification &&
      !isFetching
    );
  }, [currentOverdueIndex, OverDueAndSoonOrders.length, showedAllNotification, isFetching]);

  useEffect(() => {
    if (showNotification && playNotificationSound) {
      setPlayNotificationSound(false);
      audio.play();
    }
  }, [showNotification, playNotificationSound]);

  const handleClearFilters = useCallback(() => {
    const defaultFilters: RetailFilter = {
      country: filters.country,
      brand: filters.brand,
      branch: RetailBranch.SEEF
    };
    setFilters(defaultFilters);
    setSelectedDate(null);
    setEndDate(new Date());
  }, [filters.country, filters.brand]);

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const handleRequestSort = (property: 'createdAt' | 'pickUpAt') => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDateChange = (date: Date | null, type: 'start' | 'end') => {
    if (date && isNaN(date.getTime())) {
      setDateError(true);
    } else {
      setDateError(false);
      if (type === 'start') {
        setSelectedDate(date);
      } else {
        setEndDate(date);
      }
    }
  };

  const filteredOrders = useMemo(() => {
    return getFilteredItems({
      orderList: orders,
      filters
    });
  }, [orders, filters]);

  const sortedOrders = filteredOrders.sort((a, b) => {
    const orderMultiplier = order === 'asc' ? 1 : -1;
    if (orderBy === 'createdAt') {
      return orderMultiplier * (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    }
    if (orderBy === 'pickUpAt') {
      return orderMultiplier * (new Date(a.pickUp.pickUpAt).getTime() - new Date(b.pickUp.pickUpAt).getTime());
    }
    return 0;
  });

  const getOrdersForCurrentPage = () => {
    const startIndex = currentPage * ordersPerPage;
    const endIndex = startIndex + ordersPerPage;
    return sortedOrders.slice(startIndex, endIndex);
  };

  const paginatedOrders = getOrdersForCurrentPage();

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };

  useEffect(() => {
    if (currentBranch !== filters.branch) {
      resetBranchData(true);
    }
  }, [currentBranch, filters.branch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      resetBranchData();
    }, 300 * 1000); // 300 seconds = 5 minutes
    return () => clearInterval(intervalId);
  }, [resetBranchData]);

  const totalRevenue = useMemo(() => {
    let total = 0;
    for (const order of filteredOrders) {
      for (const item of order.items) {
        if (item.itemType !== 'LOYALTY') {
          total += item.price.amount;
        }
      }
    }
    return total;
  }, [filteredOrders]);

  return {
    filters,
    totalRevenue: +totalRevenue.toFixed(2),
    OverDueOrders: OverDueAndSoonOrders,
    setFilters,
    notification,
    setNotification,
    isUserInteracted,
    soundTest,
    needReEnableAudio,
    selectedTab,
    selectedDate,
    setSelectedDate,
    endDate,
    setEndDate,
    orderList: orderList,
    isLoading,
    isFetching,
    isUnauthorized,
    loadingItemIds,
    handleUserInteraction,
    stopPlayingSound,
    handleChangeStatus,
    handleItemUpdate,
    handleSoundTestConfirmation,
    handleTabChange,
    filteredOrders,
    filterOrdersByStatus: (status: PickUpStatus) =>
      filterOrdersByStatus({
        orderList,
        status,
        filters,
        selectedDate,
        endDate,
        searchQuery
      }),
    handleClearFilters,
    searchQuery,
    handleSearchChange,
    currentPage,
    setCurrentPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleRequestSort,
    paginatedOrders,
    handlePageChange,
    handleDateChange,
    dateError,
    resetBranchData,
    lastUpdated,
    carHopDetails,
    resetCarHopDetails,
    currentOverdueIndex,
    showNotification,
    handleOverdueOrderClose,
    setCurrentOverdueIndex,
    showedAllNotification,
    setShowedAllNotification
  };
};
