import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { getImageUrl, ImageVariant } from 'lib/helpers/getImageUrl';
import { FoodComponent } from 'lib/interfaces';
import styles from '../styles';

interface ComponentCardProps {
  selectedComponent: any;
  foodComponents: FoodComponent[];
  component: FoodComponent;
  radioCheck: boolean;
  selectedComponentIndex: number;
  selectedRemovedComponentIdList: string[];
}

const ComponentCard = ({
  component,
  selectedComponent,
  foodComponents,
  radioCheck,
  selectedRemovedComponentIdList,
  selectedComponentIndex
}: ComponentCardProps) => {
  const displayImage = getImageUrl(component.imageUrl, ImageVariant.square1x);
  return (
    <Stack sx={{ ...styles.imageContainer }}>
      {radioCheck && (
        <>
          {foodComponents[selectedComponentIndex].id === component.id && (
            <Stack
              sx={{
                ...styles.originalBox
              }}
            >
              <Typography
                sx={{
                  ...styles.originalText
                }}
              >
                Original
              </Typography>
            </Stack>
          )}
          <FormControl sx={{ ...styles.formControlBox }}>
            <RadioGroup>
              <FormControlLabel
                label={''}
                control={
                  <Radio
                    color="default"
                    onClick={() => null}
                    sx={{
                      ...styles.radioButton,
                      color: component.id === selectedComponent?.id ? caloTheme.palette.primary500 : caloTheme.palette.neutral500
                    }}
                  />
                }
                checked={component.id === selectedComponent?.id && !selectedRemovedComponentIdList.includes(component.id)}
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      <Stack sx={{ ...styles.imageBox }}>
        <figure className="image">
          <img
            alt={`${component.name?.en} Image`}
            className="object-cover rounded-s-md"
            onError={(e: any) => (e.target.src = noImagePlaceholder)}
            src={displayImage}
          />
        </figure>
      </Stack>
      <Typography sx={{ ...styles.componentNameText }}> {component?.name?.en || ''} </Typography>
    </Stack>
  );
};

export default ComponentCard;
