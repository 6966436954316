import { getDocuments } from 'actions/documents';
import { caloTheme } from 'assets/images/theme/calo';
import { DocsType, FoodStatsType } from 'lib/enums';
import { createAutoDownloadLinkFromUrl, ToastSuccessMsgs } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { GetDocumentsProps } from 'lib/interfaces';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Permission, PortionStatsFilters } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Menu, MenuItem } from '@mui/material';

interface DownloadDropdownProps {
  isDisabled: boolean;
  isLoading: boolean;
  onExport: () => void;
  filters: PortionStatsFilters & { type: FoodType; size: string[]; onlySandwiches: boolean };
  filtersCheck: {
    subscriptions: boolean;
    businesses: boolean;
    charity: boolean;
    catering: boolean;
  };
}

const DownloadDropdown = ({ isLoading, isDisabled, filtersCheck, onExport, filters }: DownloadDropdownProps) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isExportMenuOpened = Boolean(deliveryActionMenuAnchorEl);
  const roles = useUserRoles();
  const getGuidelinesAsPDF = async (values: GetDocumentsProps) => {
    setDownloadLoading(true);
    try {
      const response = await getDocuments(values);
      if (response.status === 200 && response.data.data && response.data.data.length > 0) {
        createAutoDownloadLinkFromUrl(response.data.data);
      } else if (response.status === 200 && response.data.data && response.data.data.length === 0 && response.data.message) {
        ToastSuccessMsgs({
          status: response.status,
          action: (
            <div>
              {response.data.message}
              <br /> It will be sent to your email
            </div>
          )
        });
      }
    } catch (error) {
      toast('Error downloading the document', { type: 'error', autoClose: 2000 });
      console.error('Error downloading the document', error);
    } finally {
      setDownloadLoading(false);
    }
  };
  const getStatsType = () => {
    const types: FoodStatsType[] = [];
    if (filtersCheck.subscriptions) {
      types.push(FoodStatsType.subscriptions);
    }
    if (filtersCheck.businesses) {
      types.push(FoodStatsType.businesses);
    }
    if (filtersCheck.charity) {
      types.push(FoodStatsType.charity);
    }
    if (filtersCheck.catering) {
      types.push(FoodStatsType.catering);
    }

    return types;
  };

  return (
    <>
      <LoadingButton
        variant="outlined"
        loading={downloadLoading}
        disabled={isDisabled || isLoading}
        onClick={(event) => !deliveryActionMenuAnchorEl && setDeliveryActionMenuAnchorEl(event.currentTarget)}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          textTransform: 'none',
          height: '45px',
          ml: 2,
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          color: caloTheme.palette.neutral900,
          borderColor: caloTheme.palette.neutral900,
          '&:hover': {
            backgroundColor: caloTheme.palette.neutral100,
            borderColor: caloTheme.palette.neutral900
          },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            justifyItems: 'center',
            margin: 'auto',
            marginTop: 4,
            width: 'auto'
          }
        }}
      >
        Download
      </LoadingButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'export-button'
        }}
        anchorEl={deliveryActionMenuAnchorEl}
        open={isExportMenuOpened}
        onClose={() => setDeliveryActionMenuAnchorEl(null)}
      >
        <MenuItem
          sx={{ fontWeight: 600 }}
          disabled={isDisabled}
          onClick={() => {
            onExport();
            setDeliveryActionMenuAnchorEl(null);
          }}
        >
          <Box>Stats</Box>
        </MenuItem>
        {roles.includes(Permission.GET_DOCUMENTS) && (
          <MenuItem
            sx={{ fontWeight: 600 }}
            disabled={isDisabled}
            onClick={() => {
              getGuidelinesAsPDF({
                docsTypes: [DocsType.portioningGuidelinesStats],
                kitchen: filters.kitchen,
                range: filters.day,
                shift: filters.time,
                filters: {
                  statsType: getStatsType(),
                  type: filters.type,
                  size: filters.size,
                  onlySandwiches: filters.onlySandwiches
                }
              });
              setDeliveryActionMenuAnchorEl(null);
            }}
          >
            <Box>Guidelines</Box>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default DownloadDropdown;
