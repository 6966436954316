import { RetailBranch } from '@calo/types';

export const cancellationReasons = {
  OUT_OF_STOCK: 'Items are out of stock',
  CANCELLED_BY_CUSTOMER: 'Customer asked to cancel',
  USER_CANNOT_COME: "The user can't come to the cafe",
  DELIVERY_REQUEST: 'The user wanted the food to be delivered',
  CHANGED_MIND: 'The user changed his mind',
  ACCIDENTAL_ORDER: 'Accidental order placement'
};

export const baristaEmailMapping = {
  'calocafe-ksa01@calo.app': [RetailBranch.BOXAT],
  'cafebh01@calo.app': [RetailBranch.SEEF],
  'h.alwi@calo.app': [RetailBranch.SEEF],
  'ksacaloroshn@calo.app': [RetailBranch.ROSHAN],
  'ksacaloroshn02@calo.app': [RetailBranch.SEDRA],
  'ksacalosnb@calo.app': [RetailBranch.SNB],
  'calocafeksa@calo.app': [RetailBranch.HITTEN]
};

export const notificationActions = {
  NEW_RETAIL_ORDER: 'new-retail-order',
  CUSTOMER_ARRIVED: 'customer-arrived'
};
