import { Button, Card, Typography } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import type { NotificationCardProps } from './types';

export const NewOrderCard = ({ title, onConfirm }: NotificationCardProps) => (
  <Card
    variant="outlined"
    sx={{
      position: 'fixed',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1000,
      padding: 2,
      backgroundColor: caloTheme.palette.primary.main,
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '80%',
      maxWidth: '600px'
    }}
  >
    <Typography variant="h6">{title || 'New Order'}</Typography>
    <Button color="info" variant="contained" onClick={onConfirm} sx={{ color: 'white' }}>
      Acknowledge
    </Button>
  </Card>
);
