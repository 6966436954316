import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { Coupon, CouponCategory, CouponFilters, Permission, SortingCoupons } from '@calo/dashboard-types';

import { Brand } from '@calo/types';
import { getListWithParams } from 'actions';
import { Button, CaloLoader, Icon, Pagination } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import Settings from '../Settings';
import CouponRow from './CouponRow';
import CouponStats from './CouponStats';

const CouponList = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [selected, setSelected] = useState<CouponCategory>(CouponCategory.promo);
  const [filters, setFilters] = useState<CouponFilters>({
    category: CouponCategory.promo,
    brand: Brand.CALO,
    ...JSON.parse(searchParams.get('filters') || '{}')
  });
  const roles = useUserRoles();

  const [sort, setSort] = useState<SortingCoupons>({
    orderBy: 'createdAt',
    orderMode: 'asc'
  });
  interface PaginatedCoupons {
    data: Coupon[];
    meta: {
      limit: number;
      total: number;
      promoCount: number;
      referralCount: number;
    };
  }

  const { data, isLoading } = useQuery<any, Error, PaginatedCoupons>(
    [
      'coupons',
      {
        page: page,
        filters: {
          category: filters.category,
          brand: filters.brand,
          type: filters.type,
          currency: filters.currency,
          status: filters.status,
          usedTimes: filters.usedTimes,
          expiresAt: filters.expiresAt,
          code: filters.code
        },
        sort
      }
    ],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const couponsList = data?.data;

  useEffect(() => {
    setPage(0);
    setFilters({ ...filters, category: selected });
  }, [selected]);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Coupons / {filters.brand ? filters.brand : 'All'}</li>
              </ul>
            </div>
          </div>
          {roles.includes(Permission.CREATE_COUPON) && selected === CouponCategory.promo && (
            <div className="level-right">
              <div className="level-item">
                <Button onClick={() => history.push(Routes.newCoupon)} className="mr-4" content="Create a Coupon" />
                <Button onClick={() => history.push(Routes.createBulkCoupons)} className="mr-4" content="Create Bulk Coupons" />
              </div>
            </div>
          )}
        </div>
      </section>
      <CouponStats
        selected={selected}
        setSelected={(v) => setSelected(v)}
        promo={data?.meta.promoCount || 0}
        referrals={data?.meta.referralCount || 0}
      />
      <section>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <div className="card has-table has-table-container-upper-radius">
            <div className="card-content">
              <div className="table-container overflow-y-auto">
                <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                  <thead>
                    <tr className="bg-black h-12">
                      <th style={{ color: 'white' }}>Code</th>
                      <th style={{ color: 'white' }}>Discount</th>
                      <th style={{ color: 'white' }}>Status</th>
                      {selected === CouponCategory.promo && (
                        <>
                          <th style={{ color: 'white' }}>
                            <span className="flex">
                              <p>Date created</p>
                              <Icon
                                name="sort"
                                size={4}
                                onClick={() => {
                                  setSort({
                                    ...sort,
                                    orderBy: 'createdAt',
                                    orderMode: sort.orderMode === 'asc' ? 'desc' : 'asc'
                                  });
                                }}
                                className="ml-1 mt-1"
                              />
                            </span>
                          </th>
                          <th style={{ color: 'white' }}>
                            <span className="flex">
                              <p>Expires date</p>
                              <Icon
                                name="sort"
                                size={4}
                                onClick={() => {
                                  setSort({
                                    ...sort,
                                    orderBy: 'expiresAt',
                                    orderMode: sort.orderMode === 'asc' ? 'desc' : 'asc'
                                  });
                                }}
                                className="ml-1 mt-1"
                              />
                            </span>
                          </th>
                        </>
                      )}
                      <th style={{ color: 'white' }}>Currency</th>
                      <th style={{ color: 'white' }}>
                        <span className="flex">
                          <p>Used times</p>
                          {selected === CouponCategory.promo && (
                            <Icon
                              name="sort"
                              size={4}
                              onClick={() => {
                                setSort({
                                  ...sort,
                                  orderBy: 'usedTimes',
                                  orderMode: sort.orderMode === 'asc' ? 'desc' : 'asc'
                                });
                              }}
                              className="ml-1 mt-1"
                            />
                          )}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  {data && data.data.length === 0 ? (
                    <span className="absolute w-full text-3xl mt-4 text-center font-bold text-gray-400 ">NO COUPONS</span>
                  ) : (
                    <tbody>
                      {couponsList?.map((coupon, index) => (
                        <CouponRow key={`${coupon.id}-${index}`} coupon={coupon} selected={selected} />
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
        <Settings onFilter={setFilters} filters={filters} selected={selected} />
        {data && (
          <Pagination
            isHidden={data.data.length === 0 || data.meta.total < 50}
            limit={data.meta.limit}
            total={data.meta.total}
            page={page}
            onChange={setPage}
          />
        )}
      </section>
    </>
  );
};

export default CouponList;
