import { Box, Button, Card, Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { differenceInMinutes, format } from 'date-fns/fp';
import { retailMenuDrinkItems } from 'lib/constants';
import React from 'react';
import { OrderItemType, PickUpType } from 'views/Retail/types';
import { pickUpTimeSVG } from './OrderTableRow';
interface NotificationPopUpProps {
  onClose: () => void;
  open?: boolean;
  actionButton?: React.ReactNode;
  currentOverdueIndex: number;
  totalOverdueOrders: number;
  onDismissAll: () => void;
  order: any;
}

const formattedTime = (pickUpAt) => {
  return pickUpAt ? format('hh:mm a')(new Date(pickUpAt)) : '--:--';
};

const NotificationPopUp: React.FC<NotificationPopUpProps> = ({
  onClose,
  open,
  order,
  currentOverdueIndex,
  totalOverdueOrders,
  actionButton,
  onDismissAll
}) => {
  const timeTillPickUp = differenceInMinutes(new Date(), new Date(order.pickUp.pickUpAt));

  return (
    <Dialog open={!!open} onClose={onClose}>
      <DialogContent sx={{ width: '510px' }}>
        {totalOverdueOrders > 1 && (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2">
              {currentOverdueIndex + 1} / {totalOverdueOrders}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {timeTillPickUp > 0
              ? `Order #${order.orderId} is scheduled for pickup in ${timeTillPickUp} minutes`
              : `Order #${order.orderId} is supposed to be picked up at ${formattedTime(order.pickUp.pickUpAt)} `}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body2">
            {`Please ensure it's ready`}
          </Typography>
          <Card sx={{ padding: 2, backgroundColor: '#f5f5f5', boxShadow: '0px 0px 0px 0px ', borderRadius: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Box display="flex">
                <Typography variant="body1" color="error" sx={{ fontWeight: 'bold', mr: 1 }}>
                  ●{' '}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>
                  Order details ({order.pickUp.orders[0].items.length} items)
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                {pickUpTimeSVG[order.pickUp.pickUpType]}
                <b className="ml-1 mt-1"> {order.pickUp.pickUpType === PickUpType.SELF_PICKUP ? 'Pick-up' : 'Carhop'}</b>
              </Typography>
            </Box>
            <Divider sx={{ mt: 2, mb: 3, mx: -2 }} />
            <Typography variant="body2" sx={{ mb: 2, color: 'gray' }}>
              Pickup time {formattedTime(order.pickUp.pickUpAt)}, {order.user.name}, {order.user.phoneNumber}
            </Typography>
            {order.pickUp.orders[0].items.map((item) => {
              const isDrinkCategory = item.category ? retailMenuDrinkItems[item.category] : true;
              const itemSize = isDrinkCategory && item.size ? `(${item.size})` : '';
              const itemType = item.itemType === OrderItemType.LOYALTY ? '(Loyalty)' : '';
              return (
                <>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {item.quantity} x {item.name.en} {itemSize} {itemType}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1, color: 'gray' }}>
                    {item.note}
                  </Typography>
                </>
              );
            })}
          </Card>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}>
        {totalOverdueOrders > 1 && (
          <Box display="flex" alignItems="center">
            <Button onClick={onDismissAll} variant="text" size="small" sx={{ color: 'red' }}>
              Dismiss all
            </Button>
          </Box>
        )}
        <Box>
          <Button onClick={onClose} size="small" sx={{ color: 'black' }}>
            Dismiss
          </Button>
          {actionButton}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationPopUp;
