import { KeyboardArrowDown } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

export interface ExpandableTextProps {
  text: string;
  maxHeight: number;
  isExpanded?: boolean;
}

export default function ExpandableText({ maxHeight, text }: ExpandableTextProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Stack direction={'row'} width={'100%'} onClick={() => setIsExpanded(!isExpanded)} sx={{ cursor: 'pointer' }}>
      {/* <Container sx={{ height: isExpanded ? `${maxHeight}px` : 'auto' }}> */}
      <Typography
        sx={[
          isExpanded ? { overflowY: 'scroll' } : { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
          {
            minHeight: '50px',
            maxHeight: isExpanded ? maxHeight : '50px',
            transition: 'height 200ms ease-in'
          }
        ]}
        dangerouslySetInnerHTML={{
          __html: text
        }}
      >
        {/* {text} */}
      </Typography>
      {/* </Container> */}
      <KeyboardArrowDown
        sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 200ms ease-in' }}
      ></KeyboardArrowDown>
    </Stack>
  );
}
